import { NEXT_PUBLIC_THRIVENT_BASE_URL } from '../../constants/env';
import { Logo } from '../Logo';
import { HeaderProps } from './HeaderDesktop';
import HeaderMobileContentMenu from './HeaderMobileContentMenu';
import HeaderMobileContentSearch from './HeaderMobileContentSearch';
import * as DialogPrimitive from '@radix-ui/react-dialog';
import styled from 'styled-components';

const HeaderMobileStyles = {
  Root: styled.header`
    position: sticky;
    top: 0;
    z-index: 40;
    @media screen and (min-width: 1024px) {
      display: none;
    }
  `,
};

const HeaderMobileBarStyles = {
  Root: styled.div<{ inverted: boolean }>`
    display: flex;
    align-items: center;
    height: 4rem; /* 64px */
    padding-left: 1.5rem; /* 24px */
    padding-right: 0.75rem; /* 12px */
    position: relative;
    z-index: 40;
    background-color: ${(p) =>
      p.inverted
        ? p.theme.midwestColors.neutralLightWeak
        : p.theme.midwestColors.neutralDarkHeavy};
    color: ${(p) =>
      p.inverted
        ? p.theme.midwestColors.neutralDarkHeavy
        : p.theme.midwestColors.neutralLightWeak};
    box-shadow:
      0 4px 6px -1px rgb(0 0 0 / 0.1),
      0 2px 4px -2px rgb(0 0 0 / 0.1);
  `,
  Link: styled.a`
    display: block;
    width: 110px;
    margin-right: auto;

    svg {
      width: 100%;
      height: auto;
    }
  `,
};

const HeaderMobileDialogStyles = {
  Trigger: styled.button`
    font-size: 1.75rem;
    display: grid;
    place-content: center;
    width: 2.75rem;
    height: 2.75rem;
    padding: 0;
    border-radius: 0.25em;
    border: none;
    background-color: transparent;
    color: inherit;

    &:last-of-type {
      color: ${(p) => p.theme.midwestColors.identityBrandSecondary};
    }
  `,
  Overlay: styled(DialogPrimitive.Overlay)`
    position: fixed;
    inset: 0;
    z-index: 40;
    overflow-y: auto;
    @media screen and (min-width: 1024px) {
      display: none; /* hide portal'd dialog */
    }
  `,
  Content: styled(DialogPrimitive.Content)`
    min-height: 100%;
    background-color: ${(p) => p.theme.midwestColors.neutralLightWeak};
    color: ${(p) => p.theme.midwestColors.textPrimary};
  `,
  Title: styled(DialogPrimitive.Title)`
    display: none;
  `,
  Close: styled.button`
    position: fixed;
    top: 0.625rem;
    right: 1.5rem;
    z-index: 40;
    font-size: 1.75rem;
    display: grid;
    place-content: center;
    width: 2.75rem;
    height: 2.75rem;
    padding: 0;
    border-radius: 0.25em;
    border: none;
    background-color: transparent;
    color: ${(p) => p.theme.midwestColors.identityBrandSecondary};
  `,
};

function IconSearch(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg
      width="1em"
      height="1em"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="0"
      focusable="false"
      aria-hidden
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      <path fill="none" stroke="none" d="M0 0h24v24H0z" />
      <path
        stroke="none"
        d="M15.5 14h-.79l-.28-.27A6.471 6.471 0 0 0 16 9.5 6.5 6.5 0 1 0 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"
      />
    </svg>
  );
}

function IconMenu(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg
      width="1em"
      height="1em"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="0"
      focusable="false"
      aria-hidden
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      <path fill="none" stroke="none" d="M0 0h24v24H0z" />
      <path stroke="none" d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z" />
    </svg>
  );
}

function IconClose(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg
      width="1em"
      height="1em"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="0"
      focusable="false"
      aria-hidden
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      <path fill="none" stroke="none" d="M0 0h24v24H0z" />
      <path
        stroke="none"
        d="M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
      />
    </svg>
  );
}

const thriventBaseUrl = NEXT_PUBLIC_THRIVENT_BASE_URL || '';

function HeaderMobileBar({
  inverted,
  children,
  baseUrl = thriventBaseUrl,
}: {
  inverted: boolean;
  children?: React.ReactNode;
  baseUrl?: string;
}) {
  return (
    <HeaderMobileBarStyles.Root inverted={inverted}>
      <HeaderMobileBarStyles.Link href={`${baseUrl}/`}>
        <Logo inverse={!inverted} />
      </HeaderMobileBarStyles.Link>
      {children}
    </HeaderMobileBarStyles.Root>
  );
}

function HeaderMobileDialog({
  inverted,
  children,
  triggerIcon,
  triggerText,
}: {
  inverted: boolean;
  children: React.ReactNode;
  triggerIcon: React.ReactElement;
  triggerText: string;
}) {
  return (
    <DialogPrimitive.Root>
      <DialogPrimitive.Trigger asChild>
        <HeaderMobileDialogStyles.Trigger
          onClick={() => document.body.classList.add('header-is-open')}
          aria-label={triggerText}
        >
          {triggerIcon}
        </HeaderMobileDialogStyles.Trigger>
      </DialogPrimitive.Trigger>

      <DialogPrimitive.Portal>
        <HeaderMobileDialogStyles.Overlay>
          <HeaderMobileDialogStyles.Content aria-describedby={undefined}>
            <HeaderMobileDialogStyles.Title>
              Menu
            </HeaderMobileDialogStyles.Title>
            <header data-testid="header-wrapper">
              <HeaderMobileBar inverted={inverted} />
              {children}
              <DialogPrimitive.Close asChild>
                <HeaderMobileDialogStyles.Close
                  onClick={() =>
                    document.body.classList.remove('header-is-open')
                  }
                  aria-label="Close"
                  data-testid="close button"
                >
                  <IconClose />
                </HeaderMobileDialogStyles.Close>
              </DialogPrimitive.Close>
            </header>
          </HeaderMobileDialogStyles.Content>
        </HeaderMobileDialogStyles.Overlay>
      </DialogPrimitive.Portal>
    </DialogPrimitive.Root>
  );
}

export default function HeaderMobile(props: HeaderProps) {
  const inverted = props.inverted ?? false;
  return (
    <HeaderMobileStyles.Root {...props} data-testid="HeaderMobile">
      <HeaderMobileBar inverted={inverted}>
        <HeaderMobileDialog
          inverted={inverted}
          triggerIcon={<IconSearch />}
          triggerText="Open Search Dialog"
        >
          <HeaderMobileContentSearch />
        </HeaderMobileDialog>
        <HeaderMobileDialog
          inverted={inverted}
          triggerIcon={<IconMenu />}
          triggerText="Open Menu Dialog"
        >
          <HeaderMobileContentMenu
            isLoggedIn={props.isLoggedIn}
            logInOrOut={props.logInOrOut}
          />
        </HeaderMobileDialog>
      </HeaderMobileBar>
    </HeaderMobileStyles.Root>
  );
}
