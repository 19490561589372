import { Logo } from '../Logo';
import { NavSecondary } from './HeaderDesktop';
import { HeaderSearchForm } from './HeaderSearchForm';
import {
  ACTION_TEAMS_URL,
  CLAIMS_URL,
  CONTACT_US_URL,
} from './HeaderUrlConstants';
import * as Dialog from '@radix-ui/react-dialog';
import Image from 'next/image';
import Link from 'next/link';
import styled from 'styled-components';

const DialogStyles = {
  DialogOverlay: styled(Dialog.DialogOverlay)`
    background-color: ${(p) => p.theme.midwestColors.neutralDarkHeavy};
    position: fixed;
    z-index: 60;
    inset: 0;
    animation: overlayShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
  `,
  Content: styled(Dialog.Content)`
    /* change color after */
    background-color: ${(p) => p.theme.midwestColors.neutralDarkHeavy};
    color: white;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    animation: contentShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
    z-index: 80;
  `,
  SearchFlex: styled.div`
    display: flex;
    align-items: flex-start;
    gap: 1rem;
    margin-inline: auto;
    margin-block: 1rem;
    width: 100%;
    max-width: 78rem;
  `,
  Logo: styled(Logo)`
    margin-top: 0.125rem;
  `,
  CloseWrapper: styled.div`
    display: flex;
    justify-content: flex-end;
  `,
  Close: styled(Dialog.Close)`
    cursor: pointer;
    border: none;
    color: ${(p) => p.theme.midwestColors.neutralDarkHeavy};
    background: none;
  `,
  Options: styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 3rem 4rem;
    margin-inline: auto;
    background-color: ${(p) => p.theme.midwestColors.neutralLightStrong};
    width: 100%;
    max-width: 78rem;
  `,
  Flex: styled.div`
    display: flex;
    gap: 2rem;
  `,
  Card: styled(Link)`
    all: unset;
    flex-grow: 1;
    padding: 2rem;
    background-color: ${(p) => p.theme.midwestColors.neutralLightWeak};
    color: ${(p) => p.theme.midwestColors.textPrimary};
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  `,
  CardImage: styled.div`
    height: 3rem;
    width: 3rem;
    display: flex;
    align-items: center;
  `,
  CardTitle: styled.h1`
    font-family: ${(p) => p.theme.font.secondary};
    font-size: 1rem;
  `,
  CardText: styled.p`
    font-size: 0.75rem;
  `,
  CardLink: styled.div`
    font-size: 0.75rem;
    font-weight: 600;

    color: ${(p) => p.theme.midwestColors.identityBrandTertiaryStrong};
    white-space: nowrap;
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    gap: 0.2rem;
    &:focus,
    &:hover {
      text-decoration: underline;
    }
  `,
  HelperWrapper: styled.div`
    display: flex;
    justify-content: center;
  `,
  HelperText: styled.p`
    max-width: 38rem;
    text-align: center;
    font-size: 0.8rem;
    margin: 0;
    color: ${(p) => p.theme.midwestColors.textSecondary};
  `,
};

function RightArrow(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1.2em"
      height="1.2em"
      viewBox="0 -960 960 960"
      focusable="false"
      aria-hidden
      fill="currentColor"
    >
      <path d="M686-450H190q-13 0-21.5-8.5T160-480q0-13 8.5-21.5T190-510h496L459-737q-9-9-9-21t9-21q9-9 21-9t21 9l278 278q5 5 7 10t2 11q0 6-2 11t-7 10L501-181q-9 9-21 9t-21-9q-9-9-9-21t9-21l227-227Z" />
    </svg>
  );
}

function IconClose(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg
      width="2em"
      height="2em"
      focusable="false"
      aria-hidden
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      <path d="M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
    </svg>
  );
}

export default function HeaderDesktopContentSearch({
  inverted,
}: {
  inverted: boolean;
}) {
  return (
    <>
      <DialogStyles.DialogOverlay />
      <DialogStyles.Content>
        <NavSecondary inverted={inverted} />
        <DialogStyles.SearchFlex>
          <DialogStyles.Logo inverse />
          <HeaderSearchForm />
        </DialogStyles.SearchFlex>
        <DialogStyles.Options>
          <DialogStyles.CloseWrapper>
            <DialogStyles.Close
              onClick={() => document.body.classList.remove('header-is-open')}
            >
              <IconClose />
            </DialogStyles.Close>
          </DialogStyles.CloseWrapper>
          <DialogStyles.Flex>
            <DialogStyles.Card href={CLAIMS_URL}>
              <div>
                <DialogStyles.CardImage>
                  <Image
                    src="https://cdn.thrivent.com/c4/d9/6c5225f14337b1314f2f30464290/notes-icon-mint.svg"
                    height={48}
                    width={48}
                    alt=""
                  ></Image>
                </DialogStyles.CardImage>
                <DialogStyles.CardTitle>File a claim</DialogStyles.CardTitle>
                <DialogStyles.CardText>
                  Need to file an insurance claim? We&rsquo;ll make the process
                  as supportive, simple and swift as possible.
                </DialogStyles.CardText>
                <DialogStyles.CardLink>
                  Get Started
                  <RightArrow />
                </DialogStyles.CardLink>
              </div>
            </DialogStyles.Card>

            <DialogStyles.Card href={ACTION_TEAMS_URL}>
              <div>
                <DialogStyles.CardImage>
                  <Image
                    src="https://cdn.thrivent.com/2a/86/720a3906467aa8f660149752ca09/team-mint.svg"
                    height={48}
                    width={48}
                    alt=""
                  ></Image>
                </DialogStyles.CardImage>
                <DialogStyles.CardTitle>Action Teams</DialogStyles.CardTitle>
                <DialogStyles.CardText>
                  If you want to make an impact in your community but
                  aren&rsquo;t sure where to begin, we&rsquo;re here to help.
                </DialogStyles.CardText>
                <DialogStyles.CardLink>
                  Learn More <RightArrow />
                </DialogStyles.CardLink>
              </div>
            </DialogStyles.Card>

            <DialogStyles.Card href={CONTACT_US_URL}>
              <div>
                <DialogStyles.CardImage>
                  <Image
                    src="https://cdn.thrivent.com/6e/9f/cc54548648a68bd51e6b24cc9cc3/contact-support.svg"
                    height={48}
                    width={48}
                    alt=""
                  ></Image>
                </DialogStyles.CardImage>
                <DialogStyles.CardTitle>Contact Support</DialogStyles.CardTitle>
                <DialogStyles.CardText>
                  Can&rsquo;t find what you&rsquo;re looking for? Need to
                  discuss a complex question? Let us know—we&rsquo;re happy to
                  help.
                </DialogStyles.CardText>
                <DialogStyles.CardLink>
                  Connect with us <RightArrow />
                </DialogStyles.CardLink>
              </div>
            </DialogStyles.Card>
          </DialogStyles.Flex>
          <DialogStyles.HelperWrapper>
            <DialogStyles.HelperText>
              Use the search bar above to find information throughout our
              website. Or choose a topic you want to learn more about.
            </DialogStyles.HelperText>
          </DialogStyles.HelperWrapper>
        </DialogStyles.Options>
      </DialogStyles.Content>
    </>
  );
}
