import styled from 'styled-components';

// --------------------------------------------------------------------------

export const containerWidthRem = 78;
export const containerWidthPixels = 1248;

export const containerPaddingRem = 1;
export const containerPaddingPixels = 16;

export const containerWidthWithPaddingPixels =
  containerWidthPixels + containerPaddingPixels;

export const sectionContainerBreakpoint = `@media screen and (min-width: ${containerWidthPixels}px)`;
export const sectionContainerWithPaddingBreakpoint = `@media screen and (min-width: ${containerWidthWithPaddingPixels}px)`;

// --------------------------------------------------------------------------

export const Container = styled.div`
  width: 100%;
  height: 100%;
  max-width: ${containerWidthRem}rem;
  padding-inline: ${containerPaddingRem}rem;
  margin-inline: auto;
`;
