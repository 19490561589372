import { showCreditUnionModalAtom } from '../CreditUnionModal';
import * as urlConstants from './HeaderUrlConstants';
import * as Accordion from '@radix-ui/react-accordion';
import { useAtom } from 'jotai';
import { useState } from 'react';
import styled, { css } from 'styled-components';

const anchorButtonCss = css`
  font-size: 1rem;
  font-weight: 530;
  text-decoration: none;
  white-space: nowrap;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 0.25rem;
  padding-block: 0.75rem;
  padding-inline: 1rem;
  border-radius: 0.375rem;
  border-width: 2px;
  border-style: solid;
`;

const Root = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-block-end: 5rem; /* linked to ListCta height */
  overflow: hidden auto;
  position: absolute;
  inset: 4rem 0 0;
`;

const ListPrimary = {
  Root: styled.ul`
    list-style: none;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    padding: 1.5rem;
    margin: 0;
  `,
  GroupTrigger: styled.button<{ 'data-state-visible'?: boolean }>`
    font-size: 1.125rem;
    font-family: ${(p) => p.theme.font.secondary};
    font-weight: 400;
    display: ${(p) => (p['data-state-visible'] ? 'flex' : 'none')};
    align-items: center;
    justify-content: space-between;
    gap: 0.25rem;
    width: 100%;
    padding: 0;
    margin-bottom: 1.5rem;
    border-width: 1px;
    border-style: solid;
    border-color: transparent;
    background: none;
  `,
  GroupContent: styled.div<{ 'data-state-visible'?: boolean }>`
    display: ${(p) => (p['data-state-visible'] ? 'flex' : 'none')};
    flex-direction: column-reverse;
    gap: 1.5rem;
  `,
  GroupClose: styled.button`
    font-size: 1.125rem;
    font-family: ${(p) => p.theme.font.secondary};
    font-weight: 400;
    text-align: left;
    display: flex;
    align-items: center;
    gap: 0.25rem;
    padding: 0;
    margin-left: -0.5rem; /* offsets icon whitespace */
    border-width: 1px;
    border-style: solid;
    border-color: transparent;
    color: ${(p) => p.theme.midwestColors.identityBrandTertiaryStrong};
    background: none;
  `,
  NavList: styled.ul`
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 1em;
    padding: 0;
    margin: 0;
  `,
  SubNavList: styled.ul`
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 1em;
    padding: 0;
    margin: 0;
  `,
  NavLink: styled.a`
    text-decoration: none;
    color: ${(p) => p.theme.midwestColors.textPrimary};
  `,
  NavButton: styled.button`
    color: ${(p) => p.theme.midwestColors.textPrimary};
    border: none;
    padding: 0;
    background: none;
    font-size: inherit;
  `,
  SecondaryTrigger: styled(Accordion.Trigger)`
    text-decoration: none;
    color: ${(p) => p.theme.midwestColors.textPrimary};
    font-size: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.25rem;
    width: 100%;
    padding: 0;
    border-width: 1px;
    border-style: solid;
    border-color: transparent;
    background: none;
    &[data-state='open'] svg {
      rotate: 90deg;
    }
  `,
  AccordionTrigger: styled(Accordion.Trigger)`
    font-size: 1.125rem;
    font-family: ${(p) => p.theme.font.secondary};
    font-weight: 400;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.25rem;
    width: 100%;
    padding: 0;
    border-width: 1px;
    border-style: solid;
    border-color: transparent;
    &[data-state='open'] svg {
      rotate: 90deg;
    }
  `,
  AccordionContent: styled(Accordion.Content)`
    padding-block: 1rem;
  `,
};

const ListSecondary = {
  Root: styled.ul`
    list-style: none;
    display: flex;
    align-items: center;
    gap: 1rem;
    width: 100%;
    height: 5rem; /* linked to Root padding-block-end */
    margin: 0;
    padding-block: 0;
    padding-inline: 1.5rem;
    position: fixed;
    bottom: 0;
    background-color: ${(p) => p.theme.midwestColors.neutralDarkHeavy};
    color: ${(p) => p.theme.midwestColors.neutralLightWeak};
  `,
  Item: styled.li`
    flex-grow: 1;
  `,
  ButtonSecondary: styled.button`
    ${anchorButtonCss}
    width: 100%;
    border-color: transparent;
    background-color: ${(p) => p.theme.midwestColors.neutralDarkMedium};
    color: ${(p) => p.theme.midwestColors.neutralLightWeak};
    &:focus,
    &:hover {
      background-color: transparent;
      outline: 1px solid ${(p) => p.theme.midwestColors.identityBrandSecondary};
    }
  `,
  AnchorButtonPrimary: styled.a`
    ${anchorButtonCss}
    width: 100%;
    border-color: transparent;
    background-color: ${(p) =>
      p.theme.midwestColors.identityBrandPrimaryMedium};
    color: ${(p) => p.theme.midwestColors.neutralLightWeak};
    &:focus,
    &:hover {
      background-color: ${(p) =>
        p.theme.midwestColors.identityBrandPrimaryStrong};
    }
  `,
};

const ListTertiary = {
  Root: styled.ul<{ 'data-state-visible'?: boolean }>`
    list-style: none;
    display: ${(p) => (p['data-state-visible'] ? 'flex' : 'none')};
    flex-direction: column;
    gap: 1em;
    padding: 1.5rem;
    margin: 0;
  `,
};

function IconChevronLeft(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg
      width="1.5em"
      height="1.5em"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="0"
      focusable={false}
      aria-hidden
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      <path fill="none" d="M0 0h24v24H0z" />
      <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z" />
    </svg>
  );
}

function IconChevronRight(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg
      width="1.5em"
      height="1.5em"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="0"
      focusable={false}
      aria-hidden
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      <path fill="none" d="M0 0h24v24H0z" />
      <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z" />
    </svg>
  );
}

export default function HeaderMobileContentMenu(
  props: React.ComponentPropsWithoutRef<'div'> & {
    isLoggedIn?: boolean;
    logInOrOut: () => void;
  },
) {
  const [, setOpen] = useAtom(showCreditUnionModalAtom);
  const [activeGroupIndex, setActiveGroupIndex] = useState<number>(0);
  const isGroupVisible = activeGroupIndex === 0;

  return (
    <Root {...props}>
      <ListPrimary.Root>
        <li>
          <ListPrimary.GroupTrigger
            type="button"
            onClick={() => setActiveGroupIndex(1)}
            data-state-visible={isGroupVisible}
          >
            <span>Your needs</span> <IconChevronRight />
          </ListPrimary.GroupTrigger>
          <ListPrimary.GroupContent data-state-visible={activeGroupIndex === 1}>
            <ListPrimary.NavList>
              <li>
                <ListPrimary.NavLink
                  href={urlConstants.CREATE_FINANCIAL_STRATEGY_URL}
                  data-analytics-name="your-needs/create-a-financial-strategy"
                >
                  Create a financial strategy
                </ListPrimary.NavLink>
              </li>
              <li>
                <ListPrimary.NavLink
                  href={urlConstants.EVERYDAY_FINANCES_URL}
                  data-analytics-name="your-needs/manage-day-to-day-finances"
                >
                  Manage everyday finances
                </ListPrimary.NavLink>
              </li>
              <li>
                <ListPrimary.NavLink
                  href={urlConstants.SAVE_FOR_RETIREMENT_URL}
                  data-analytics-name="your-needs/save-for-retirement"
                >
                  Save & build for retirement
                </ListPrimary.NavLink>
              </li>
              <li>
                <ListPrimary.NavLink
                  href={urlConstants.INVESTMENT_DECISIONS_URL}
                  data-analytics-name="your-needs/make-investment-decisions"
                >
                  Make investment decisions
                </ListPrimary.NavLink>
              </li>
              <li>
                <ListPrimary.NavLink
                  href={urlConstants.PROTECT_WHATS_IMPORTANT_URL}
                  data-analytics-name="your-needs/protect-whats-important"
                >
                  Protect what&rsquo;s important
                </ListPrimary.NavLink>
              </li>
              <li>
                <ListPrimary.NavLink
                  href={urlConstants.GIVE_BACK_URL}
                  data-analytics-name="your-needs/generosity"
                >
                  Give back
                </ListPrimary.NavLink>
              </li>
            </ListPrimary.NavList>
            <ListPrimary.GroupClose
              type="button"
              onClick={() => setActiveGroupIndex(0)}
            >
              <IconChevronLeft /> <span>Your needs</span>
            </ListPrimary.GroupClose>
          </ListPrimary.GroupContent>
        </li>
        <li>
          <ListPrimary.GroupTrigger
            type="button"
            onClick={() => setActiveGroupIndex(2)}
            data-state-visible={isGroupVisible}
          >
            <span>Our solutions</span> <IconChevronRight />
          </ListPrimary.GroupTrigger>
          <ListPrimary.GroupContent data-state-visible={activeGroupIndex === 2}>
            <Accordion.Root type="single" collapsible>
              <ListPrimary.NavList>
                <li>
                  <Accordion.Item value="item-advice-services">
                    <ListPrimary.SecondaryTrigger>
                      <span>Advice services</span> <IconChevronRight />
                    </ListPrimary.SecondaryTrigger>
                    <ListPrimary.AccordionContent>
                      <ListPrimary.SubNavList>
                        <li>
                          <ListPrimary.NavLink
                            href={urlConstants.DEDICATED_PLANNING_URL}
                            data-analytics-name="our-solutions/financial-planning"
                          >
                            Dedicated planning
                          </ListPrimary.NavLink>
                        </li>
                        <li>
                          <ListPrimary.NavLink
                            href={urlConstants.PERSONAL_TRUST_URL}
                            data-analytics-name="our-solutions/trust-services"
                          >
                            Personal trust
                          </ListPrimary.NavLink>
                        </li>
                        <li>
                          <ListPrimary.NavLink
                            href={urlConstants.ADVICE_URL}
                            data-analytics-name="our-solutions/advice"
                          >
                            All advice services
                          </ListPrimary.NavLink>
                        </li>
                      </ListPrimary.SubNavList>
                    </ListPrimary.AccordionContent>
                  </Accordion.Item>
                </li>
                <li>
                  <Accordion.Item value="item-insurance">
                    <ListPrimary.SecondaryTrigger>
                      <span>Insurance</span> <IconChevronRight />
                    </ListPrimary.SecondaryTrigger>
                    <ListPrimary.AccordionContent>
                      <ListPrimary.SubNavList>
                        <li>
                          <ListPrimary.NavLink
                            href={urlConstants.LIFE_INSURANCE_URL}
                            data-analytics-name="our-solutions/life-insurance"
                          >
                            Life insurance
                          </ListPrimary.NavLink>
                        </li>
                        <li>
                          <ListPrimary.NavLink
                            href={urlConstants.DISABILITY_INSURANCE_URL}
                            data-analytics-name="our-solutions/disability-insurance"
                          >
                            Disability insurance
                          </ListPrimary.NavLink>
                        </li>
                        <li>
                          <ListPrimary.NavLink
                            href={urlConstants.LONG_TERM_CARE_INSURANCE_URL}
                            data-analytics-name="our-solutions/long-term-care-insurance-select-state"
                          >
                            Long-term care insurance
                          </ListPrimary.NavLink>
                        </li>
                        <li>
                          <ListPrimary.NavLink
                            href={urlConstants.INSURANCE_URL}
                            data-analytics-name="our-solutions/insurance"
                          >
                            All insurance
                          </ListPrimary.NavLink>
                        </li>
                      </ListPrimary.SubNavList>
                    </ListPrimary.AccordionContent>
                  </Accordion.Item>
                </li>
                <li>
                  <Accordion.Item value="item-investments">
                    <ListPrimary.SecondaryTrigger>
                      <span>Investments</span> <IconChevronRight />
                    </ListPrimary.SecondaryTrigger>
                    <ListPrimary.AccordionContent>
                      <ListPrimary.SubNavList>
                        <li>
                          <ListPrimary.NavLink
                            href={urlConstants.MUTUAL_FUNDS_URL}
                            data-analytics-name="our-solutions/mutual-funds"
                          >
                            Mutual funds
                          </ListPrimary.NavLink>
                        </li>
                        <li>
                          <ListPrimary.NavLink
                            href={urlConstants.MANAGED_ACCOUNTS_URL}
                            data-analytics-name="our-solutions/managed-accounts"
                          >
                            Managed accounts
                          </ListPrimary.NavLink>
                        </li>
                        <li>
                          <ListPrimary.NavLink
                            href={urlConstants.ALL_INVESTMENTS_URL}
                            data-analytics-name="our-solutions/investments"
                          >
                            All investments
                          </ListPrimary.NavLink>
                        </li>
                      </ListPrimary.SubNavList>
                    </ListPrimary.AccordionContent>
                  </Accordion.Item>
                </li>
                <li>
                  <ListPrimary.NavLink
                    href={urlConstants.ANNUITIES_URL}
                    data-analytics-name="our-solutions/annuities"
                  >
                    Annuities
                  </ListPrimary.NavLink>
                </li>
                <li>
                  <Accordion.Item value="item-generosity">
                    <ListPrimary.SecondaryTrigger>
                      <span>Generosity</span> <IconChevronRight />
                    </ListPrimary.SecondaryTrigger>
                    <ListPrimary.AccordionContent>
                      <ListPrimary.SubNavList>
                        <li>
                          <ListPrimary.NavLink
                            href="https://www.thriventcharitable.com/"
                            data-analytics-name="about-us/charitable"
                            target="_blank"
                          >
                            Thrivent Charitable
                          </ListPrimary.NavLink>
                        </li>
                        <li>
                          <ListPrimary.NavLink
                            href={urlConstants.GENEROSITY_URL}
                            data-analytics-name="our-solutions/generosity"
                          >
                            All Generosity
                          </ListPrimary.NavLink>
                        </li>
                      </ListPrimary.SubNavList>
                    </ListPrimary.AccordionContent>
                  </Accordion.Item>
                </li>
                <li>
                  <Accordion.Item value="item-banking">
                    <ListPrimary.SecondaryTrigger>
                      <span>Banking</span> <IconChevronRight />
                    </ListPrimary.SecondaryTrigger>
                    <ListPrimary.AccordionContent>
                      <ListPrimary.SubNavList>
                        <li>
                          <ListPrimary.NavLink
                            href="https://www.thriventchurchloans.com/"
                            data-analytics-name="our-solutions/church-loans"
                          >
                            Church Loans
                          </ListPrimary.NavLink>
                        </li>
                        <li>
                          <ListPrimary.NavButton onClick={() => setOpen(true)}>
                            Credit Union
                          </ListPrimary.NavButton>
                        </li>
                        <li>
                          <ListPrimary.NavLink
                            href={urlConstants.BANKING_URL}
                            data-analytics-name="our-solutions/banking"
                          >
                            All Banking
                          </ListPrimary.NavLink>
                        </li>
                      </ListPrimary.SubNavList>
                    </ListPrimary.AccordionContent>
                  </Accordion.Item>
                </li>
              </ListPrimary.NavList>
            </Accordion.Root>
            <ListPrimary.GroupClose
              type="button"
              onClick={() => setActiveGroupIndex(0)}
            >
              <IconChevronLeft /> <span>Our solutions</span>
            </ListPrimary.GroupClose>
          </ListPrimary.GroupContent>
        </li>
        <li>
          <ListPrimary.GroupTrigger
            type="button"
            onClick={() => setActiveGroupIndex(3)}
            data-state-visible={isGroupVisible}
          >
            <span>About us</span> <IconChevronRight />
          </ListPrimary.GroupTrigger>
          <ListPrimary.GroupContent data-state-visible={activeGroupIndex === 3}>
            <ListPrimary.NavList>
              <li>
                <ListPrimary.NavLink
                  href={urlConstants.ABOUT_US_URL}
                  data-analytics-name="about-us/"
                >
                  About us
                </ListPrimary.NavLink>
              </li>
              <li>
                <ListPrimary.NavLink
                  href={urlConstants.PURPOSE_AND_VALUES_URL}
                  data-analytics-name="about-us/purpose-and-values"
                >
                  Purpose & values
                </ListPrimary.NavLink>
              </li>
              <li>
                <ListPrimary.NavLink
                  href={urlConstants.LEADERSHIP_URL}
                  data-analytics-name="about-us/our-leadership"
                >
                  Thrivent leadership
                </ListPrimary.NavLink>
              </li>
              <li>
                <ListPrimary.NavLink
                  href={urlConstants.FINANCIALS_URL}
                  data-analytics-name="about-us/financials"
                >
                  Financials
                </ListPrimary.NavLink>
              </li>
              <li>
                <ListPrimary.NavLink
                  href="https://newsroom.thrivent.com"
                  data-analytics-name="about-us/newsroom"
                >
                  Newsroom
                </ListPrimary.NavLink>
              </li>
              <li>
                <ListPrimary.NavLink
                  href={urlConstants.MEMBERSHIP_URL}
                  data-analytics-name="about-us/membership"
                >
                  Membership
                </ListPrimary.NavLink>
              </li>
              <li>
                <ListPrimary.NavLink
                  href="https://artcollection.thrivent.com/"
                  data-analytics-name="about-us/collection"
                >
                  Art Collection
                </ListPrimary.NavLink>
              </li>
              <li>
                <ListPrimary.NavLink
                  href={urlConstants.GENEROSITY_AND_GIVING_URL}
                  data-analytics-name="about-us/how-we-give-back"
                >
                  Generosity & giving
                </ListPrimary.NavLink>
              </li>
              <li>
                <ListPrimary.NavLink
                  href={urlConstants.OUR_HISTORY_URL}
                  data-analytics-name="about-us/history"
                >
                  Our history
                </ListPrimary.NavLink>
              </li>
              <li>
                <ListPrimary.NavLink
                  href={urlConstants.CONTACT_US_URL}
                  data-analytics-name="about-us/support"
                >
                  Contact us
                </ListPrimary.NavLink>
              </li>
              <li>
                <ListPrimary.NavLink
                  href={urlConstants.FREQUENTLY_ASKED_QUESTIONS_URL}
                  data-analytics-name="about-us/faq"
                >
                  FAQ
                </ListPrimary.NavLink>
              </li>
            </ListPrimary.NavList>
            <ListPrimary.GroupClose
              type="button"
              onClick={() => setActiveGroupIndex(0)}
            >
              <IconChevronLeft /> <span>About us</span>
            </ListPrimary.GroupClose>
          </ListPrimary.GroupContent>
        </li>
        <li>
          <ListPrimary.GroupTrigger
            type="button"
            onClick={() => setActiveGroupIndex(4)}
            data-state-visible={isGroupVisible}
          >
            <span>Insights & guidance</span> <IconChevronRight />
          </ListPrimary.GroupTrigger>
          <ListPrimary.GroupContent data-state-visible={activeGroupIndex === 4}>
            <ListPrimary.NavList>
              <li>
                <ListPrimary.NavLink
                  href={urlConstants.STARTING_OUT_FINANCIALLY_URL}
                  data-analytics-name="insights/starting-out-financially"
                >
                  Starting out financially
                </ListPrimary.NavLink>
              </li>
              <li>
                <ListPrimary.NavLink
                  href={urlConstants.BALANCING_WORK_AND_FAMILY_URL}
                  data-analytics-name="insights/balancing-work-family"
                >
                  Balancing work & family
                </ListPrimary.NavLink>
              </li>
              <li>
                <ListPrimary.NavLink
                  href={urlConstants.NEARING_RETIREMENT_URL}
                  data-analytics-name="insights/nearing-retirement"
                >
                  Nearing retirement
                </ListPrimary.NavLink>
              </li>
              <li>
                <ListPrimary.NavLink
                  href={urlConstants.IN_RETIREMENT_URL}
                  data-analytics-name="insights/living-in-retirement"
                >
                  Living in retirement
                </ListPrimary.NavLink>
              </li>
              <li>
                <ListPrimary.NavLink
                  href={urlConstants.INSIGHT_AND_GUIDANCE_URL}
                  data-analytics-name="insights/"
                >
                  All insights & guidance
                </ListPrimary.NavLink>
              </li>
              <li>
                <ListPrimary.NavLink
                  href={urlConstants.MEMBER_NETWORKS_URL}
                  data-analytics-name="insights/member-networks"
                >
                  Thrivent Member Networks
                </ListPrimary.NavLink>
              </li>
              <li>
                <ListPrimary.NavLink
                  href={urlConstants.MAGAZINE_URL}
                  data-analytics-name="insights/magazine"
                >
                  Thrivent Magazine
                </ListPrimary.NavLink>
              </li>
              <li>
                <ListPrimary.NavLink
                  href={urlConstants.ECONOMIC_UPDATE_URL}
                  data-analytics-name="insights/market-economic-update"
                >
                  Market & Economic Update
                </ListPrimary.NavLink>
              </li>
              <li>
                <ListPrimary.NavLink
                  href={urlConstants.TOOLS_AND_CALCULATORS_URL}
                  data-analytics-name="insights/tools-and-calculators"
                >
                  Tools & Calculators
                </ListPrimary.NavLink>
              </li>
              <li>
                <ListPrimary.NavLink
                  href={urlConstants.FINANCIAL_EDUCATION_EVENTS_URL}
                  data-analytics-name="insights/financial-education-events"
                >
                  Events
                </ListPrimary.NavLink>
              </li>
            </ListPrimary.NavList>
            <ListPrimary.GroupClose
              type="button"
              onClick={() => setActiveGroupIndex(0)}
            >
              <IconChevronLeft /> <span>Insights & guidance</span>
            </ListPrimary.GroupClose>
          </ListPrimary.GroupContent>
        </li>
      </ListPrimary.Root>

      <ListSecondary.Root>
        <ListSecondary.Item>
          <ListSecondary.ButtonSecondary
            onClick={props.logInOrOut}
            data-analytics-id={
              props.isLoggedIn ? 'log-out-button' : 'log-in-button'
            }
          >
            {props.isLoggedIn ? 'Log out' : 'Log in'}
          </ListSecondary.ButtonSecondary>
        </ListSecondary.Item>
        <ListSecondary.Item>
          <ListSecondary.AnchorButtonPrimary
            href="https://local.thrivent.com/"
            data-analytics-cta-text="Find a financial advisor Header CTA"
          >
            Find a Financial Advisor
          </ListSecondary.AnchorButtonPrimary>
        </ListSecondary.Item>
      </ListSecondary.Root>

      <ListTertiary.Root data-state-visible={isGroupVisible}>
        <li>
          <ListPrimary.NavLink
            href="https://careers.thrivent.com/"
            data-analytics-name="mobile-careers"
          >
            Careers
          </ListPrimary.NavLink>
        </li>
        <li>
          <ListPrimary.NavLink
            href={urlConstants.CLAIMS_URL}
            data-analytics-name="mobile-claims"
          >
            Claims
          </ListPrimary.NavLink>
        </li>
        {props.isLoggedIn && (
          <li>
            <ListPrimary.NavLink
              href={process.env['NEXT_PUBLIC_SERVICING_URL']}
              target="_blank"
              rel="noreferrer"
            >
              Account Overview
            </ListPrimary.NavLink>
          </li>
        )}
      </ListTertiary.Root>
    </Root>
  );
}
