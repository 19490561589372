import { HeaderSearchFormMobile } from './HeaderSearchFormMobile';
import {
  ACTION_TEAMS_URL,
  CLAIMS_URL,
  CONTACT_US_URL,
} from './HeaderUrlConstants';
import Image from 'next/image';
import styled from 'styled-components';

const HeaderMobileContentSearchStyles = {
  Root: styled.div``,
  List: styled.ul`
    list-style: none;
    margin: 0;
    padding: 1.5rem;
  `,
  ListItem1: styled.li`
    padding-block-end: 0.75rem;
  `,
  ListItemN: styled.li`
    padding-block: 1.25rem;
    border-width: 0;
    border-style: solid;
    border-color: ${(p) => p.theme.midwestColors.neutralLightStrong};
    &:not(:last-child) {
      border-bottom-width: 1px;
    }
  `,
  TopicRoot: styled.a`
    display: flex;
    gap: 1.25em;
    text-decoration: none;
  `,
  TopicText: styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.25em;
  `,
  TopicTitle: styled.div`
    font-size: 1rem;
    font-family: ${(p) => p.theme.font.secondary};
    font-weight: 630;
    color: ${(p) => p.theme.midwestColors.textPrimary};
  `,
  TopicLink: styled.div`
    font-size: 0.8rem;
    font-weight: 600;
    color: ${(p) => p.theme.midwestColors.identityBrandTertiaryStrong};
    white-space: nowrap;
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    gap: 0.2rem;
    &:focus,
    &:hover {
      text-decoration: underline;
    }
  `,
};

function RightArrow(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1.2em"
      height="1.2em"
      viewBox="0 -960 960 960"
      focusable="false"
      aria-hidden
    >
      <path d="M686-450H190q-13 0-21.5-8.5T160-480q0-13 8.5-21.5T190-510h496L459-737q-9-9-9-21t9-21q9-9 21-9t21 9l278 278q5 5 7 10t2 11q0 6-2 11t-7 10L501-181q-9 9-21 9t-21-9q-9-9-9-21t9-21l227-227Z" />
    </svg>
  );
}

export default function HeaderMobileContentSearch(
  props: React.ComponentPropsWithoutRef<'div'>,
) {
  return (
    <HeaderMobileContentSearchStyles.Root {...props}>
      <HeaderMobileContentSearchStyles.List>
        <HeaderMobileContentSearchStyles.ListItem1>
          <HeaderSearchFormMobile />
        </HeaderMobileContentSearchStyles.ListItem1>
        <HeaderMobileContentSearchStyles.ListItemN>
          <HeaderMobileContentSearchStyles.TopicRoot href={CLAIMS_URL}>
            <Image
              src="https://cdn.thrivent.com/c4/d9/6c5225f14337b1314f2f30464290/notes-icon-mint.svg"
              height={48}
              width={48}
              alt=""
            ></Image>
            <HeaderMobileContentSearchStyles.TopicText>
              <HeaderMobileContentSearchStyles.TopicTitle>
                File a claim
              </HeaderMobileContentSearchStyles.TopicTitle>
              <HeaderMobileContentSearchStyles.TopicLink>
                Get Started <RightArrow />
              </HeaderMobileContentSearchStyles.TopicLink>
            </HeaderMobileContentSearchStyles.TopicText>
          </HeaderMobileContentSearchStyles.TopicRoot>
        </HeaderMobileContentSearchStyles.ListItemN>
        <HeaderMobileContentSearchStyles.ListItemN>
          <HeaderMobileContentSearchStyles.TopicRoot href={ACTION_TEAMS_URL}>
            <Image
              src="https://cdn.thrivent.com/2a/86/720a3906467aa8f660149752ca09/team-mint.svg"
              height={48}
              width={48}
              alt=""
            ></Image>
            <HeaderMobileContentSearchStyles.TopicText>
              <HeaderMobileContentSearchStyles.TopicTitle>
                Action Teams
              </HeaderMobileContentSearchStyles.TopicTitle>
              <HeaderMobileContentSearchStyles.TopicLink>
                Learn More <RightArrow />
              </HeaderMobileContentSearchStyles.TopicLink>
            </HeaderMobileContentSearchStyles.TopicText>
          </HeaderMobileContentSearchStyles.TopicRoot>
        </HeaderMobileContentSearchStyles.ListItemN>
        <HeaderMobileContentSearchStyles.ListItemN>
          <HeaderMobileContentSearchStyles.TopicRoot href={CONTACT_US_URL}>
            <Image
              src="https://cdn.thrivent.com/6e/9f/cc54548648a68bd51e6b24cc9cc3/contact-support.svg"
              height={48}
              width={48}
              alt=""
            ></Image>
            <HeaderMobileContentSearchStyles.TopicText>
              <HeaderMobileContentSearchStyles.TopicTitle>
                Contact support
              </HeaderMobileContentSearchStyles.TopicTitle>
              <HeaderMobileContentSearchStyles.TopicLink>
                Connect with us <RightArrow />
              </HeaderMobileContentSearchStyles.TopicLink>
            </HeaderMobileContentSearchStyles.TopicText>
          </HeaderMobileContentSearchStyles.TopicRoot>
        </HeaderMobileContentSearchStyles.ListItemN>
      </HeaderMobileContentSearchStyles.List>
    </HeaderMobileContentSearchStyles.Root>
  );
}
