'use client';

import {
  AnchorBaseButtonProps,
  AnchorButtonProps,
} from '../../Actions/Button/types';
import {
  BaseButtonStyle,
  DestructiveButtonStyle,
  DestructiveGhostButtonStyle,
  GhostButtonStyle,
  PrimaryButtonStyle,
  SecondaryButtonStyle,
} from '../Button/ButtonStyles';
import { forwardRef } from 'react';
import styled from 'styled-components';

const BaseButton = styled.a<AnchorBaseButtonProps>`
  ${BaseButtonStyle}
`;

const PrimaryButton = styled(BaseButton)<AnchorButtonProps>`
  ${PrimaryButtonStyle}
`;

const SecondaryButton = styled(BaseButton)<AnchorButtonProps>`
  ${SecondaryButtonStyle}
`;

const DestructiveButton = styled(BaseButton)`
  ${DestructiveButtonStyle}
`;

const GhostButton = styled(BaseButton)`
  ${GhostButtonStyle}
`;

const DestructiveGhostButton = styled(BaseButton)`
  ${DestructiveGhostButtonStyle}
`;

const anchorVariations = {
  primary: PrimaryButton,
  secondary: SecondaryButton,
  destructive: DestructiveButton,
  ghost: GhostButton,
  destructiveGhost: DestructiveGhostButton,
};

/**
 * Anchor tag styled to look like a button. For more documentation on variants/sizing see the [Deprecated Button](https://midwest-storybook.icweb.stage.aws.tfcloud.corp/?path=/docs/thrivent-web-components-deprecated-deprecated-button--docs) component.
 *
 * @deprecated Use new MDSAnchorButton from @midwest/web/button instead
 * */
export const MDSAnchorButton = forwardRef<HTMLAnchorElement, AnchorButtonProps>(
  (
    {
      children,
      variant = 'primary',
      size = 'normal',
      onClick,
      href,
      target = '_self',
      fullWidth,
      ...rest
    }: AnchorButtonProps,
    ref?: React.Ref<HTMLAnchorElement>,
  ) => {
    const AnchorVariation = anchorVariations[variant];

    const handleKeydown = (e: React.KeyboardEvent<HTMLAnchorElement>) => {
      if (e.key === 'Enter' || e.key === ' ') {
        e.preventDefault();
        e.currentTarget.click();
      }
    };

    return (
      <AnchorVariation
        target={target}
        onClick={onClick}
        variant={variant}
        size={size}
        href={href}
        fullWidth={fullWidth}
        {...rest}
        ref={ref}
        tabIndex={0}
        onKeyDown={handleKeydown}
      >
        {children}
      </AnchorVariation>
    );
  },
);

MDSAnchorButton.displayName = 'MDSAnchorButton';
