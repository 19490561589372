import { NEXT_PUBLIC_THRIVENT_HEADER_BASE_URL } from '../../constants/env';

const thriventHeaderBaseUrl = NEXT_PUBLIC_THRIVENT_HEADER_BASE_URL || '';

const CLIENT_RESOURCES = '/client-resources';

//content about
const ABOUT_US = '/about-us';
const PURPOSE_AND_VALUES = '/about-us/purpose-and-values';
const CLIENT_STORIES = '/client-stories';
const LEADERSHIP = '/about-us/our-leadership';
const FINANCIALS = '/about-us/financials';
const MEMBERSHIP = '/about-us/membership';
const GENEROSITY_AND_GIVING = '/about-us/how-we-give-back';
const OUR_HISTORY = '/about-us/history';
const CONTACT_US = '/about-us/support';
const FREQUENTLY_ASKED_QUESTIONS = '/about-us/faq';

//content insights
const STARTING_OUT_FINANCIALLY = '/insights/starting-out-financially';
const BALANCING_WORK_AND_FAMILY = '/insights/balancing-work-family';
const NEARING_RETIREMENT = '/insights/nearing-retirement';
const IN_RETIREMENT = '/insights/living-in-retirement';
const INSIGHT_AND_GUIDANCE = '/insights';
const MEMBER_NETWORKS = '/about-us/member-networks';
const MAGAZINE = '/insights/magazine';
const ECONOMIC_UPDATE = '/market-economic-update';
const TOOLS_AND_CALCULATORS = '/tools-and-calculators';
const FINANCIAL_EDUCATION_EVENTS = '/financial-education-events';
const FEATURED_MAGAZINE = '/insights/magazine/fall-2024';

//content needs
const CREATE_FINANCIAL_STRATEGY = '/create-a-financial-strategy';
const EVERYDAY_FINANCES = '/manage-day-to-day-finances';
const SAVE_FOR_RETIREMENT = '/save-for-retirement';
const INVESTMENT_DECISIONS = '/make-investment-decisions';
const PROTECT_WHATS_IMPORTANT = '/protect-whats-important';
const GIVE_BACK = '/generosity';
const FEATURED =
  '/insights/from-our-leadership/what-makes-thrivent-unlike-any-other-financial-services-organization';

//content solutions
const PRODUCT_BASED_SOLUTIONS = '/advice/product-based-solutions';
const DEDICATED_PLANNING = '/advice/financial-planning';
const PERSONAL_TRUST = '/advice/trust-services';
const ADVICE = '/advice';
const LIFE_INSURANCE = '/insurance/life-insurance';
const DISABILITY_INSURANCE = '/insurance/disability-insurance';
const LONG_TERM_CARE_INSURANCE =
  '/insurance/long-term-care-insurance-select-state';
const INSURANCE = '/insurance';
const MUTUAL_FUNDS = '/investments/mutual-funds';
const MANAGED_ACCOUNTS = '/investments/managed-accounts';
const ALL_INVESTMENTS = '/investments';
const ANNUITIES = '/annuities';
const GENEROSITY = '/generosity';
const BANKING = '/banking';
const MULTI_YEAR = '/annuities/fixed-annuities/myga';

const CLAIMS = '/claims';
const ACTION_TEAMS = '/about-us/membership/thrivent-action-teams';

const urlMaker = (url: string) => {
  return thriventHeaderBaseUrl ? `${thriventHeaderBaseUrl}${url}` : url;
};

export const CLIENT_RESOURCES_URL = urlMaker(CLIENT_RESOURCES);

export const ABOUT_US_URL = urlMaker(ABOUT_US);
export const PURPOSE_AND_VALUES_URL = urlMaker(PURPOSE_AND_VALUES);
export const CLIENT_STORIES_URL = urlMaker(CLIENT_STORIES);
export const LEADERSHIP_URL = urlMaker(LEADERSHIP);
export const FINANCIALS_URL = urlMaker(FINANCIALS);
export const MEMBERSHIP_URL = urlMaker(MEMBERSHIP);
export const GENEROSITY_AND_GIVING_URL = urlMaker(GENEROSITY_AND_GIVING);
export const OUR_HISTORY_URL = urlMaker(OUR_HISTORY);
export const CONTACT_US_URL = urlMaker(CONTACT_US);
export const FREQUENTLY_ASKED_QUESTIONS_URL = urlMaker(
  FREQUENTLY_ASKED_QUESTIONS,
);

export const STARTING_OUT_FINANCIALLY_URL = urlMaker(STARTING_OUT_FINANCIALLY);
export const BALANCING_WORK_AND_FAMILY_URL = urlMaker(
  BALANCING_WORK_AND_FAMILY,
);
export const NEARING_RETIREMENT_URL = urlMaker(NEARING_RETIREMENT);
export const IN_RETIREMENT_URL = urlMaker(IN_RETIREMENT);
export const INSIGHT_AND_GUIDANCE_URL = urlMaker(INSIGHT_AND_GUIDANCE);
export const MEMBER_NETWORKS_URL = urlMaker(MEMBER_NETWORKS);
export const MAGAZINE_URL = urlMaker(MAGAZINE);
export const ECONOMIC_UPDATE_URL = urlMaker(ECONOMIC_UPDATE);
export const TOOLS_AND_CALCULATORS_URL = urlMaker(TOOLS_AND_CALCULATORS);
export const FINANCIAL_EDUCATION_EVENTS_URL = urlMaker(
  FINANCIAL_EDUCATION_EVENTS,
);
export const FEATURED_MAGAZINE_URL = urlMaker(FEATURED_MAGAZINE);

export const CREATE_FINANCIAL_STRATEGY_URL = urlMaker(
  CREATE_FINANCIAL_STRATEGY,
);
export const EVERYDAY_FINANCES_URL = urlMaker(EVERYDAY_FINANCES);
export const SAVE_FOR_RETIREMENT_URL = urlMaker(SAVE_FOR_RETIREMENT);
export const INVESTMENT_DECISIONS_URL = urlMaker(INVESTMENT_DECISIONS);
export const PROTECT_WHATS_IMPORTANT_URL = urlMaker(PROTECT_WHATS_IMPORTANT);
export const GIVE_BACK_URL = urlMaker(GIVE_BACK);
export const FEATURED_URL = urlMaker(FEATURED);

export const PRODUCT_BASED_SOLUTIONS_URL = urlMaker(PRODUCT_BASED_SOLUTIONS);
export const DEDICATED_PLANNING_URL = urlMaker(DEDICATED_PLANNING);
export const PERSONAL_TRUST_URL = urlMaker(PERSONAL_TRUST);
export const ADVICE_URL = urlMaker(ADVICE);
export const LIFE_INSURANCE_URL = urlMaker(LIFE_INSURANCE);
export const DISABILITY_INSURANCE_URL = urlMaker(DISABILITY_INSURANCE);
export const LONG_TERM_CARE_INSURANCE_URL = urlMaker(LONG_TERM_CARE_INSURANCE);
export const INSURANCE_URL = urlMaker(INSURANCE);
export const MUTUAL_FUNDS_URL = urlMaker(MUTUAL_FUNDS);
export const MANAGED_ACCOUNTS_URL = urlMaker(MANAGED_ACCOUNTS);
export const ALL_INVESTMENTS_URL = urlMaker(ALL_INVESTMENTS);
export const ANNUITIES_URL = urlMaker(ANNUITIES);
export const GENEROSITY_URL = urlMaker(GENEROSITY);
export const BANKING_URL = urlMaker(BANKING);
export const MULTI_YEAR_URL = urlMaker(MULTI_YEAR);

export const CLAIMS_URL = urlMaker(CLAIMS);
export const ACTION_TEAMS_URL = urlMaker(ACTION_TEAMS);
