import { NEXT_PUBLIC_THRIVENT_HEADER_BASE_URL } from '../../constants/env';
import { Info_Outline } from '@midwest/web/base';
import { colors } from '@thrivent/midwest-shared';
import { useSearchParams } from 'next/navigation';
import { useState } from 'react';
import styled from 'styled-components';

const HeaderSearchFormStyles = {
  Form: styled.form`
    flex-grow: 1;
  `,
  FormWrapper: styled.div`
    position: relative;
    flex-grow: 1;
    display: flex;
    gap: 1rem;
  `,
  Error: styled.span`
    text-align: center;
    color: ${colors.sentimentNegativeWeak};
    font-size: 0.875rem;
    margin-top: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
  `,
  ErrorIcon: styled(Info_Outline ?? 'span')`
    fill: ${colors.sentimentNegativeWeak};
    height: 1rem;
    width: 1rem;
    margin-right: 0.25rem;
  `,
  Input: styled.input<{ $error: boolean }>`
    flex-grow: 1;
    padding-block: 0.75rem;
    padding-inline: 1rem;
    border-radius: 0.375rem;
    background-color: #fff;
    border: ${(p) =>
      p.$error ? '2px solid ' + colors.sentimentNegativeMedium : 'none'};
    outline: none;
    text-align: center;
    color: ${(p) => p.theme.midwestColors.neutralDarkHeavy};

    ::placeholder {
      color: ${(p) => p.theme.midwestColors.neutralDarkWeak};
      font-family: ${(p) => p.theme.font.secondary};
    }
    :-ms-input-placeholder {
      color: ${(p) => p.theme.midwestColors.neutralDarkWeak};
      font-family: ${(p) => p.theme.font.secondary};
    }

    ::-ms-input-placeholder {
      color: ${(p) => p.theme.midwestColors.neutralDarkWeak};
      font-family: ${(p) => p.theme.font.secondary};
    }
  `,
  Button: styled.button`
    font-size: 1rem;
    font-weight: 530;
    text-decoration: none;
    white-space: nowrap;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    gap: 0.25rem;
    padding-block: 0.75rem;
    padding-inline: 1rem;
    border-radius: 0.375rem;
    border-width: 2px;
    border-style: solid;
    border-color: transparent;
    background-color: ${(p) =>
      p.theme.midwestColors.identityBrandPrimaryMedium};
    color: ${(p) => p.theme.midwestColors.neutralLightWeak};
    &:focus,
    &:hover {
      cursor: pointer;
      background-color: ${(p) =>
        p.theme.midwestColors.identityBrandPrimaryStrong};
    }
  `,
};

const StyledSearchIcon = styled.svg`
  position: absolute;
  top: 50%;
  right: 7.5rem;
  transform: translateY(-50%);
  color: ${(p) => p.theme.midwestColors.identityBrandPrimaryMedium};
`;

function IconSearch(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <StyledSearchIcon
      width="1.5em"
      height="1.5em"
      focusable="false"
      aria-hidden
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 27 26"
      fill="currentColor"
    >
      <circle
        cx="10.627"
        cy="9.91"
        r="6"
        fill="transparent"
        stroke="currentColor"
        strokeWidth="2"
      />
      <rect
        width="2"
        height="9.875"
        x="13.633"
        y="14.329"
        transform="rotate(-45 13.633 14.33)"
      />
    </StyledSearchIcon>
  );
}

const thriventHeaderBaseUrl = NEXT_PUBLIC_THRIVENT_HEADER_BASE_URL || '';

export const SEARCH_VALUE_PARAM_KEY = 'q';
export const SEARCH_FILTERS_PARAM_KEY1 = 'f0';
export const SEARCH_FILTERS_PARAM_KEY2 = 'f1';

interface FormData {
  [SEARCH_VALUE_PARAM_KEY]?: { value: string };
  [SEARCH_FILTERS_PARAM_KEY1]?: { value: string };
  [SEARCH_FILTERS_PARAM_KEY2]?: { value: string };
}

export function HeaderSearchForm({ value }: { value?: string }) {
  const searchParams = useSearchParams();
  const [error, setError] = useState<string | null>(null);

  // term
  const searchTermParamValue = searchParams?.get(SEARCH_VALUE_PARAM_KEY) || '';
  let searchTermValue = value;
  if (!value && Array.isArray(searchTermParamValue)) {
    searchTermValue = searchTermParamValue[0];
  } else if (!value && !Array.isArray(searchTermParamValue)) {
    searchTermValue = searchTermParamValue || undefined;
  }

  // filters
  const searchFiltersParamValue1 =
    searchParams?.getAll(SEARCH_FILTERS_PARAM_KEY1) || [];
  const searchFiltersParamValue2 =
    searchParams?.getAll(SEARCH_FILTERS_PARAM_KEY2) || [];

  const createFilter1Inputs = () => {
    return searchFiltersParamValue1.map((filter) => {
      return (
        <input
          type="hidden"
          data-testid="search-filters"
          name={SEARCH_FILTERS_PARAM_KEY1}
          value={filter}
          key={filter}
        />
      );
    });
  };

  const createFilter2Inputs = () => {
    return searchFiltersParamValue2.map((filter) => {
      return (
        <input
          type="hidden"
          data-testid="search-filters"
          name={SEARCH_FILTERS_PARAM_KEY2}
          value={filter}
          key={filter}
        />
      );
    });
  };

  return (
    <HeaderSearchFormStyles.Form
      onSubmit={(e) => {
        if (
          !(e.target as typeof e.target & FormData)?.[SEARCH_VALUE_PARAM_KEY]
            ?.value
        ) {
          e.preventDefault();
          setError('Enter a search term');
        }
      }}
      action={
        thriventHeaderBaseUrl ? `${thriventHeaderBaseUrl}/search` : '/search'
      }
    >
      <HeaderSearchFormStyles.FormWrapper>
        <IconSearch />
        <HeaderSearchFormStyles.Input
          type="text"
          name={SEARCH_VALUE_PARAM_KEY}
          id={SEARCH_VALUE_PARAM_KEY}
          data-testid="search-value-input"
          placeholder="What can we help you find?"
          defaultValue={searchTermValue}
          autoComplete="off"
          $error={!!error}
        />
        {createFilter1Inputs()}
        {createFilter2Inputs()}
        <HeaderSearchFormStyles.Button type="submit">
          Search
        </HeaderSearchFormStyles.Button>
      </HeaderSearchFormStyles.FormWrapper>
      {!!error && (
        <HeaderSearchFormStyles.Error role="alert">
          <HeaderSearchFormStyles.ErrorIcon />
          {error}
        </HeaderSearchFormStyles.Error>
      )}
    </HeaderSearchFormStyles.Form>
  );
}
