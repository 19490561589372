import {
  HeaderDesktopContentStyles,
  HeaderDesktopContentTriple,
} from './HeaderDesktopContent';
import {
  BALANCING_WORK_AND_FAMILY_URL,
  ECONOMIC_UPDATE_URL,
  FEATURED_MAGAZINE_URL,
  FINANCIAL_EDUCATION_EVENTS_URL,
  INSIGHT_AND_GUIDANCE_URL,
  IN_RETIREMENT_URL,
  MAGAZINE_URL,
  MEMBER_NETWORKS_URL,
  NEARING_RETIREMENT_URL,
  STARTING_OUT_FINANCIALLY_URL,
  TOOLS_AND_CALCULATORS_URL,
} from './HeaderUrlConstants';
import * as NavigationMenu from '@radix-ui/react-navigation-menu';
import styled from 'styled-components';

const HeaderDesktopContentInsightsStyles = {
  Root: styled.ul`
    font-size: 1rem;
    margin: 0;
    column-count: 2;
    column-gap: 1em;
    li:not(:last-child) {
      margin-bottom: 1em;
    }
    a {
      text-decoration: none;
      color: ${(p) => p.theme.midwestColors.textPrimary};

      &:focus,
      &:hover {
        color: ${(p) => p.theme.midwestColors.identityBrandTertiaryStrong};
      }
    }
  `,
};

function RightArrow(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1.2em"
      height="1.2em"
      viewBox="0 -960 960 960"
      focusable="false"
      aria-hidden
      fill="currentColor"
    >
      <path d="M686-450H190q-13 0-21.5-8.5T160-480q0-13 8.5-21.5T190-510h496L459-737q-9-9-9-21t9-21q9-9 21-9t21 9l278 278q5 5 7 10t2 11q0 6-2 11t-7 10L501-181q-9 9-21 9t-21-9q-9-9-9-21t9-21l227-227Z" />
    </svg>
  );
}

export default function HeaderDesktopContentInsights(
  props: Omit<React.ComponentPropsWithoutRef<'div'>, 'children'>,
) {
  return (
    <HeaderDesktopContentTriple
      {...props}
      colStart={
        <HeaderDesktopContentStyles.OverviewRoot>
          <HeaderDesktopContentStyles.OverviewTitle>
            Insights & guidance
          </HeaderDesktopContentStyles.OverviewTitle>
          <HeaderDesktopContentStyles.OverviewDescription>
            We offer a wealth of knowledge and resources to help you stay
            informed and on track to meet your financial goals.
          </HeaderDesktopContentStyles.OverviewDescription>
        </HeaderDesktopContentStyles.OverviewRoot>
      }
      colCenter={
        <HeaderDesktopContentInsightsStyles.Root>
          <li>
            <NavigationMenu.Link asChild>
              <a
                href={STARTING_OUT_FINANCIALLY_URL}
                data-analytics-name="insights/starting-out-financially"
              >
                Starting out financially
              </a>
            </NavigationMenu.Link>
          </li>
          <li>
            <NavigationMenu.Link asChild>
              <a
                href={BALANCING_WORK_AND_FAMILY_URL}
                data-analytics-name="insights/balancing-work-family"
              >
                Balancing work & family
              </a>
            </NavigationMenu.Link>
          </li>
          <li>
            <NavigationMenu.Link asChild>
              <a
                href={NEARING_RETIREMENT_URL}
                data-analytics-name="insights/nearing-retirement"
              >
                Nearing retirement
              </a>
            </NavigationMenu.Link>
          </li>
          <li>
            <NavigationMenu.Link asChild>
              <a
                href={IN_RETIREMENT_URL}
                data-analytics-name="insights/living-in-retirement"
              >
                Living in retirement
              </a>
            </NavigationMenu.Link>
          </li>
          <li>
            <NavigationMenu.Link asChild>
              <a
                href={INSIGHT_AND_GUIDANCE_URL}
                data-analytics-name="insights/"
              >
                All insights & guidance
              </a>
            </NavigationMenu.Link>
          </li>
          <li>
            <NavigationMenu.Link asChild>
              <a
                href={MEMBER_NETWORKS_URL}
                data-analytics-name="insights/member-networks"
              >
                Thrivent Member Networks
              </a>
            </NavigationMenu.Link>
          </li>
          <li>
            <NavigationMenu.Link asChild>
              <a href={MAGAZINE_URL} data-analytics-name="insights/magazine">
                Thrivent Magazine
              </a>
            </NavigationMenu.Link>
          </li>
          <li>
            <NavigationMenu.Link asChild>
              <a
                href={ECONOMIC_UPDATE_URL}
                data-analytics-name="insights/market-economic-update"
              >
                Market & Economic Update
              </a>
            </NavigationMenu.Link>
          </li>
          <li>
            <NavigationMenu.Link asChild>
              <a
                href={TOOLS_AND_CALCULATORS_URL}
                data-analytics-name="insights/tools-and-calculators"
              >
                Tools & Calculators
              </a>
            </NavigationMenu.Link>
          </li>
          <li>
            <NavigationMenu.Link asChild>
              <a
                href={FINANCIAL_EDUCATION_EVENTS_URL}
                data-analytics-name="insights/financial-education-events"
              >
                Events
              </a>
            </NavigationMenu.Link>
          </li>
        </HeaderDesktopContentInsightsStyles.Root>
      }
      colEnd={
        <HeaderDesktopContentStyles.FeatureRoot>
          <HeaderDesktopContentStyles.FeatureText>
            Featured
          </HeaderDesktopContentStyles.FeatureText>
          <HeaderDesktopContentStyles.FeatureContent>
            <HeaderDesktopContentStyles.FeatureTitle>
              Thrivent Magazine &mdash; Fall 2024 issue
            </HeaderDesktopContentStyles.FeatureTitle>
            <HeaderDesktopContentStyles.FeatureDescription>
              The weather may be cooling down—but your ever-changing family and
              financial needs probably are not. Get tips on how to balance them
              all, along with strategies for claiming Social Security and how to
              manage finances as an empty nester.
            </HeaderDesktopContentStyles.FeatureDescription>
          </HeaderDesktopContentStyles.FeatureContent>
          <div>
            <HeaderDesktopContentStyles.FeatureLink
              href={FEATURED_MAGAZINE_URL}
              aria-label="Read the Thrivent Magazine Fall 2024 issue"
              data-analytics-name="insights/magazine"
            >
              Read now <RightArrow />
            </HeaderDesktopContentStyles.FeatureLink>
          </div>
        </HeaderDesktopContentStyles.FeatureRoot>
      }
    />
  );
}
