import { CreditUnionModal } from '../CreditUnionModal';
import FallbackHeader from './FallbackHeader';
import { hideExplorationHeaderAtom } from './Header.atom';
import HeaderDesktop from './HeaderDesktop';
import HeaderMobile from './HeaderMobile';
import { useAuth } from '@thrivent-web/authentication';
import { hasCookie } from 'cookies-next';
import { useAtom } from 'jotai';
import { useCallback, useEffect, useState } from 'react';

const loggedInCookie = 'ThriventLoggedIn';

type Props = {
  inverted?: boolean;
  hideLoginButton?: boolean;
  hideFAButton?: boolean;
};

export default function Header(props: Props) {
  const [hideExplorationHeader] = useAtom(hideExplorationHeaderAtom);
  const [, setHasLoggedInCookie] = useState(false);

  // hasCookie is not available in SSR context
  // put into hook to avoid SSR/Client render mismatch when user is logged in
  useEffect(() => {
    setHasLoggedInCookie(hasCookie(loggedInCookie));
  }, []);

  const { signinRedirect, signoutSilent, isAuthenticated } = useAuth();

  const logInOrOut = useCallback(async () => {
    if (isAuthenticated) {
      await signoutSilent();
    } else {
      await signinRedirect();
    }
  }, [isAuthenticated, signinRedirect, signoutSilent]);

  return hideExplorationHeader ? (
    <FallbackHeader />
  ) : (
    <>
      <CreditUnionModal />
      <HeaderMobile
        inverted={props.inverted}
        isLoggedIn={isAuthenticated}
        logInOrOut={logInOrOut}
      />
      <HeaderDesktop
        inverted={props.inverted}
        isLoggedIn={isAuthenticated}
        logInOrOut={logInOrOut}
        hideLoginButton={props.hideLoginButton}
        hideFAButton={props.hideFAButton}
      />
    </>
  );
}
