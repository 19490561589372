import { NEXT_PUBLIC_THRIVENT_HEADER_BASE_URL } from '../../constants/env';
import { Container } from '../Container';
import { Logo } from '../Logo';
import HeaderDesktopContentAbout from './HeaderDesktopContentAbout';
import HeaderDesktopContentInsights from './HeaderDesktopContentInsights';
import HeaderDesktopContentNeeds from './HeaderDesktopContentNeeds';
import HeaderDesktopContentSearch from './HeaderDesktopContentSearch';
import HeaderDesktopContentSolutions from './HeaderDesktopContentSolutions';
import { CLIENT_RESOURCES_URL } from './HeaderUrlConstants';
import { Close } from '@midwest/web/base';
import { Button } from '@mui/material';
import * as Dialog from '@radix-ui/react-dialog';
import * as NavigationMenu from '@radix-ui/react-navigation-menu';
import { MDSAnchorButton, MDSButton } from '@thrivent/midwest-web';
import { Dispatch, PointerEventHandler, SetStateAction, useState } from 'react';
import styled, { css } from 'styled-components';

const anchorButtonCss = css`
  font-size: 1rem;
  font-weight: 530;
  text-decoration: none;
  white-space: nowrap;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 0.25rem;
  padding-block: 0.75rem;
  padding-inline: 1rem;
  border-radius: 0.375rem;
  border-width: 2px;
  border-style: solid;
`;

const NavSecondaryStyles = {
  Root: styled.nav<{ inverted: boolean }>`
    font-size: 0.875rem;
    background-color: ${(p) =>
      p.inverted
        ? p.theme.midwestColors.neutralLightWeak
        : p.theme.midwestColors.neutralDarkHeavy};
    color: ${(p) =>
      p.inverted
        ? p.theme.midwestColors.neutralDarkHeavy
        : p.theme.midwestColors.neutralLightWeak};
  `,
  Row1: styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;
  `,
  Row1Spacer: styled.div`
    flex-grow: 1;
  `,
  Row1List: styled.ul`
    display: flex;
    align-items: center;
    gap: 0.25rem;
    margin-block: 0;
    margin-right: -0.75rem; /* offsets anchor padding-inline */
    list-style: none;
    a {
      text-decoration: none;
      display: block;
      padding: 0.75rem;
      color: inherit;
      &:hover,
      &:focus {
        color: ${(p) => p.theme.midwestColors.identityBrandSecondary};
      }
    }
  `,
};

const StyledCaret = styled.svg`
  transition: transform 250ms ease;
  fill: currentColor;
`;

const NavTriggerStyles = {
  Root: styled(NavigationMenu.Trigger)`
    ${anchorButtonCss}
    font-weight: 400;
    padding: 0.75rem;
    border-color: transparent;
    color: inherit;
    background-color: transparent;

    &:hover,
    &:focus {
      color: ${(p) => p.theme.midwestColors.identityBrandSecondary};
      cursor: pointer;
    }
    &[data-state='open'] > ${StyledCaret} {
      transform: rotate(180deg);
    }
  `,
};

const CloseButtonStyle = styled(Button)`
  &.MuiButton-root {
    position: absolute;
  }
  right: 0;
  height: 3rem;
`;

const DarkOverlay = styled.div`
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: -1;
`;

const NavContentStyles = {
  Root: styled(NavigationMenu.Content)`
    margin-top: 1.5rem;
    border-top-width: 4px;
    border-top-style: solid;
    border-top-color: ${(p) => p.theme.midwestColors.identityBrandSecondary};
    background-color: ${(p) => p.theme.midwestColors.neutralLightWeak};
    color: ${(p) => p.theme.midwestColors.neutralDarkHeavy};
  `,
};

const HeaderDesktopStyles = {
  Root: styled.header`
    display: none;
    flex-direction: column-reverse; /* NavSecondary last = better a11y */
    width: 100%;
    top: 0;
    z-index: 40;
    @media screen and (min-width: 1024px) {
      display: flex;
      position: sticky;
    }

    ul {
      list-style: none;
      padding-left: 0;
    }
  `,
  MenuRoot: styled(NavigationMenu.Root)`
    position: relative;
    z-index: 40;
  `,
  MenuWrapper: styled.div<{ inverted: boolean }>`
    padding-block: 1.25rem;
    background-color: ${(p) =>
      p.inverted
        ? p.theme.midwestColors.neutralLightWeak
        : p.theme.midwestColors.neutralDarkHeavy};
    color: ${(p) =>
      p.inverted
        ? p.theme.midwestColors.neutralDarkHeavy
        : p.theme.midwestColors.neutralLightWeak};
  `,
  MenuList: styled(NavigationMenu.List)`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-block: 0;
    padding-left: 0;
  `,
  MenuIndicator: styled(NavigationMenu.Indicator)`
    bottom: -1.5rem;
    height: 0.25rem;
    translate: 0 -100%;
    transition: all;
    background-color: ${(p) => p.theme.midwestColors.identityBrandSecondary};
  `,
  LogoItem: styled(NavigationMenu.Item)`
    flex-shrink: 0;
    margin-right: auto;
    translate: 0 -3px; /* Align vertically to menu items */
    @media screen and (max-width: 1199px) {
      position: absolute;
      top: -0.625rem;
      translate: 0 -100%;
    }
  `,
  LogoLink: styled.a`
    display: block;
    width: 165px; /* Resizes svg to roughly 165x36px, height being the most important. */
  `,
  MenuItem: styled(NavigationMenu.Item)`
    flex-shrink: 0;
    &:nth-child(2) {
      @media screen and (max-width: 1199px) {
        margin-left: -1rem; /* offsets anchor padding-inline-start */
      }
    }
    &:nth-child(6n) {
      margin-right: auto;
    }
    &:nth-child(n + 7):not(:last-child) {
      margin-right: 1rem;
    }
  `,
  MenuLink: styled(NavigationMenu.Link)<{ inverted: boolean }>`
    border-color: transparent;
    background-color: ${(p) => p.theme.midwestColors.neutralDarkMedium};
    color: ${(p) => p.theme.midwestColors.neutralLightWeak};
    &:focus,
    &:hover {
      background-color: transparent;
      color: ${(p) =>
        p.inverted
          ? p.theme.midwestColors.neutralDarkMedium
          : p.theme.midwestColors.neutralLightWeak};
      outline: 1px solid ${(p) => p.theme.midwestColors.identityBrandSecondary};
    }
  `,
  SearchButton: styled.button`
    background-color: transparent;
    border: none;
    cursor: pointer;
    color: ${(p) => p.theme.midwestColors.neutralLightWeak};
    font-size: 1rem;
    display: flex;
    &:focus,
    &:hover {
      color: ${(p) => p.theme.midwestColors.identityBrandSecondary};
    }
  `,
  CtaItem: styled(NavigationMenu.Item)`
    flex-shrink: 0;
  `,
  CtaLink: styled(NavigationMenu.Link)``,
  MenuViewportWrapper: styled.div`
    display: flex;
    justify-content: center;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
  `,
  MenuViewport: styled(NavigationMenu.Viewport)`
    width: 100%;
    height: var(--radix-navigation-menu-viewport-height);
    max-width: 90rem;
    position: relative;
    & > * {
      margin-block: 0; /* TODO: Remove when content is styled */
    }
  `,
};

const LogInButton = styled(MDSButton)`
  border: none;
  outline: solid 1px transparent;

  &:hover,
  &:focus {
    border: none;
  }
`;

const FindAnAdvisorButton = styled(MDSAnchorButton)`
  border: none;
  outline: solid 1px transparent;

  &:hover,
  &:focus {
    border: none;
  }
`;

function IconChevronDown(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <StyledCaret
      width="1em"
      height="1em"
      viewBox="0 0 12 12"
      focusable="false"
      aria-hidden
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M8.3 8 6 5.7 3.7 8 3 7.3l3-3 3 3-.7.7z" />
    </StyledCaret>
  );
}

function IconSearch(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg
      width="1.5em"
      height="1.5em"
      focusable="false"
      aria-hidden
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 27 26"
      fill="currentColor"
    >
      <circle
        cx="10.627"
        cy="9.91"
        r="6"
        fill="transparent"
        stroke="currentColor"
        strokeWidth="2"
      />
      <rect
        width="2"
        height="9.875"
        x="13.633"
        y="14.329"
        transform="rotate(-45 13.633 14.33)"
      />
    </svg>
  );
}

const thriventHeaderBaseUrl = NEXT_PUBLIC_THRIVENT_HEADER_BASE_URL || '';

export function NavSecondary(props: {
  inverted: boolean;
  isLoggedIn?: boolean;
}) {
  return (
    <NavSecondaryStyles.Root inverted={props.inverted} aria-label="Secondary">
      <Container>
        <NavSecondaryStyles.Row1>
          <NavSecondaryStyles.Row1Spacer />
          <NavSecondaryStyles.Row1List>
            <li>
              <a href={CLIENT_RESOURCES_URL}>Client Resources</a>
            </li>
            <li>
              <a href="https://careers.thrivent.com/">Careers</a>
            </li>
            {props.isLoggedIn && (
              <li>
                <a
                  href={process.env['NEXT_PUBLIC_SERVICING_URL']}
                  target="_blank"
                  rel="noreferrer"
                >
                  Account Overview
                </a>
              </li>
            )}
          </NavSecondaryStyles.Row1List>
        </NavSecondaryStyles.Row1>
      </Container>
    </NavSecondaryStyles.Root>
  );
}

export const preventHover: PointerEventHandler<
  HTMLButtonElement | HTMLDivElement
> = (e) => {
  e.preventDefault();
};

enum NavOption {
  NEEDS = 'NEEDS',
  SOLUTIONS = 'SOLUTIONS',
  ABOUT = 'ABOUT',
  INSIGHTS = 'INSIGHTS',
}

function NavTrigger({
  children,
  iconOnly = false,
  'aria-label': ariaLabel,
}: {
  children: React.ReactNode;
  iconOnly?: boolean;
  'aria-label'?: string;
}) {
  return (
    <NavTriggerStyles.Root
      onPointerMove={preventHover}
      onPointerLeave={preventHover}
      aria-label={ariaLabel}
    >
      {children} {iconOnly ? null : <IconChevronDown />}
    </NavTriggerStyles.Root>
  );
}

function NavContent({ children }: { children: React.ReactNode }) {
  return (
    <NavContentStyles.Root
      onPointerEnter={preventHover}
      onPointerLeave={preventHover}
    >
      {children}
    </NavContentStyles.Root>
  );
}

function CloseButton({
  setOpenNavItem,
}: {
  setOpenNavItem: Dispatch<SetStateAction<'' | NavOption>>;
}) {
  return (
    <CloseButtonStyle variant="text" onClick={() => setOpenNavItem('')}>
      <Close fill="black" />
    </CloseButtonStyle>
  );
}

export interface HeaderProps extends React.ComponentPropsWithoutRef<'header'> {
  /** Sets the `class` attribute. */
  className?: string | undefined;
  isLoggedIn?: boolean;
  logInOrOut: () => void;
  inverted?: boolean;
  hideLoginButton?: boolean;
  hideFAButton?: boolean;
}

export default function HeaderDesktop(props: HeaderProps) {
  // We are controlling the open state so that we can prevent the dropdowns from opening on hover.
  // We only want them to open on click
  const [openNavItem, setOpenNavItem] = useState<NavOption | ''>('');
  const inverted = props.inverted ?? false;
  return (
    <HeaderDesktopStyles.Root {...props} data-testid="HeaderDesktop">
      {openNavItem !== '' && <DarkOverlay />}
      <HeaderDesktopStyles.MenuRoot
        value={openNavItem}
        onValueChange={(value) => setOpenNavItem(value as NavOption)}
      >
        <HeaderDesktopStyles.MenuWrapper inverted={inverted}>
          <Container>
            <HeaderDesktopStyles.MenuList>
              <HeaderDesktopStyles.LogoItem>
                <HeaderDesktopStyles.LogoLink
                  href={
                    thriventHeaderBaseUrl ? `${thriventHeaderBaseUrl}` : '/'
                  }
                >
                  <Logo inverse={!inverted} />
                </HeaderDesktopStyles.LogoLink>
              </HeaderDesktopStyles.LogoItem>
              <HeaderDesktopStyles.MenuItem value={NavOption.NEEDS}>
                <NavTrigger aria-label="Your needs">Your needs</NavTrigger>
                <NavContent>
                  <CloseButton setOpenNavItem={setOpenNavItem} />
                  <HeaderDesktopContentNeeds />
                </NavContent>
              </HeaderDesktopStyles.MenuItem>
              <HeaderDesktopStyles.MenuItem value={NavOption.SOLUTIONS}>
                <NavTrigger aria-label="Our solutions">
                  Our solutions
                </NavTrigger>
                <NavContent>
                  <CloseButton setOpenNavItem={setOpenNavItem} />
                  <HeaderDesktopContentSolutions />
                </NavContent>
              </HeaderDesktopStyles.MenuItem>
              <HeaderDesktopStyles.MenuItem value={NavOption.ABOUT}>
                <NavTrigger aria-label="About us">About us</NavTrigger>
                <NavContent>
                  <CloseButton setOpenNavItem={setOpenNavItem} />
                  <HeaderDesktopContentAbout />
                </NavContent>
              </HeaderDesktopStyles.MenuItem>
              <HeaderDesktopStyles.MenuItem value={NavOption.INSIGHTS}>
                <NavTrigger aria-label="Insights">
                  Insights & guidance
                </NavTrigger>
                <NavContent>
                  <CloseButton setOpenNavItem={setOpenNavItem} />
                  <HeaderDesktopContentInsights />
                </NavContent>
              </HeaderDesktopStyles.MenuItem>
              <HeaderDesktopStyles.MenuItem>
                <Dialog.Root>
                  <Dialog.Trigger asChild>
                    <HeaderDesktopStyles.SearchButton
                      aria-label="Search"
                      onClick={() =>
                        document.body.classList.add('header-is-open')
                      }
                    >
                      <IconSearch aria-label="Search Icon" />
                    </HeaderDesktopStyles.SearchButton>
                  </Dialog.Trigger>
                  <Dialog.Portal>
                    <HeaderDesktopContentSearch inverted={inverted} />
                  </Dialog.Portal>
                </Dialog.Root>
              </HeaderDesktopStyles.MenuItem>
              {!props.hideLoginButton && (
                <HeaderDesktopStyles.MenuItem>
                  <HeaderDesktopStyles.MenuLink asChild inverted={inverted}>
                    <LogInButton
                      onClick={props.logInOrOut}
                      data-analytics-id={
                        props.isLoggedIn ? 'log-out-button' : 'log-in-button'
                      }
                    >
                      {props.isLoggedIn ? 'Log out' : 'Log in'}
                    </LogInButton>
                  </HeaderDesktopStyles.MenuLink>
                </HeaderDesktopStyles.MenuItem>
              )}
              {!props.hideFAButton && (
                <HeaderDesktopStyles.CtaItem>
                  <HeaderDesktopStyles.CtaLink asChild>
                    <FindAnAdvisorButton
                      href="https://local.thrivent.com"
                      data-analytics-cta-text="Find a financial advisor Header CTA"
                    >
                      Find a financial advisor
                    </FindAnAdvisorButton>
                  </HeaderDesktopStyles.CtaLink>
                </HeaderDesktopStyles.CtaItem>
              )}
              <HeaderDesktopStyles.MenuIndicator>
                <div />
              </HeaderDesktopStyles.MenuIndicator>
            </HeaderDesktopStyles.MenuList>
          </Container>
        </HeaderDesktopStyles.MenuWrapper>
        <HeaderDesktopStyles.MenuViewportWrapper>
          <HeaderDesktopStyles.MenuViewport />
        </HeaderDesktopStyles.MenuViewportWrapper>
      </HeaderDesktopStyles.MenuRoot>
      <NavSecondary inverted={inverted} isLoggedIn={props.isLoggedIn} />
    </HeaderDesktopStyles.Root>
  );
}
