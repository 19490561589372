import styled from 'styled-components';

export const HeaderDesktopContentStyles = {
  Root: styled.div`
    font-size: 0.875rem;
    line-height: 1.3;
  `,
  Row: styled.ul<{ is2Col?: boolean }>`
    display: grid;
    grid-template-columns: ${(p) => (p.is2Col ? '1fr 3fr' : '1fr 2fr 1fr')};
    margin: 0;
  `,
  ColStart: styled.li`
    position: relative;
    padding-block: 3rem;
    padding-inline: 2rem 1rem;
    list-style: none;
    @media screen and (min-width: 1440px) {
      padding-inline: 7rem 1rem;
    }
    &::after {
      content: '';
      display: block;
      width: 1px;
      background-color: ${(p) => p.theme.midwestColors.neutralLightStrong};
      position: absolute;
      top: 2rem;
      bottom: 2rem;
      right: 0;
    }
  `,
  ColCenter: styled.li`
    padding-block: 3rem;
    padding-inline: 1.5rem;
  `,
  ColEnd: styled.li<{ is2Col?: boolean }>`
    padding-block: 3rem;
    padding-inline: 1.5rem 2rem;
    @media screen and (min-width: 1440px) {
      padding-inline: 1.5rem 7rem;
    }
    background-color: ${(p) =>
      p.is2Col ? 'transparent' : p.theme.midwestColors.backgroundSecondary};
  `,
  OverviewRoot: styled.div`
    display: flex;
    flex-direction: column;
    gap: 1em;
  `,
  OverviewTitle: styled.div`
    font-family: ${(p) => p.theme.font.secondary};
    font-size: 2rem;
  `,
  OverviewDescription: styled.p`
    font-size: 1rem;
    margin: 0;
    color: ${(p) => p.theme.midwestColors.textSecondary};
  `,
  FeatureRoot: styled.article`
    display: flex;
    flex-direction: column;
    gap: 1.5em;
  `,
  FeatureText: styled.div`
    font-weight: 630;
    color: ${(p) => p.theme.midwestColors.textSecondary};
  `,
  FeatureContent: styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5em;
  `,
  FeatureTitle: styled.h4`
    font-size: 1rem;
    font-family: ${(p) => p.theme.font.primary};
    font-weight: 630;
    margin: 0;
  `,
  FeatureDescription: styled.p`
    margin: 0;
    color: ${(p) => p.theme.midwestColors.textSecondary};
  `,
  FeatureLink: styled.a`
    font-weight: 630;
    text-decoration: none;
    color: ${(p) => p.theme.midwestColors.identityBrandTertiaryStrong};
    white-space: nowrap;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 0.2rem;
    &:focus,
    &:hover {
      text-decoration: underline;
    }
  `,
};

export interface HeaderDesktopContentSingleProps
  extends React.ComponentPropsWithoutRef<'div'> {
  /** Sets the content. **Required** */
  children: React.ReactNode;
}

export function HeaderDesktopContentSingle({
  children,
  ...props
}: HeaderDesktopContentSingleProps) {
  return (
    <HeaderDesktopContentStyles.Root {...props}>
      {children}
    </HeaderDesktopContentStyles.Root>
  );
}

export interface HeaderDesktopContentTripleProps
  extends Omit<React.ComponentPropsWithoutRef<'div'>, 'children'> {
  /** Sets the start column content. **Required** */
  colStart: React.ReactNode;
  /** Sets the center column content. **Required** */
  colCenter?: React.ReactNode;
  /** Sets the end column  content. **Required** */
  colEnd: React.ReactNode;
}

export function HeaderDesktopContentTriple({
  colStart,
  colCenter,
  colEnd,
  ...props
}: HeaderDesktopContentTripleProps) {
  return (
    <HeaderDesktopContentStyles.Root {...props}>
      <HeaderDesktopContentStyles.Row is2Col={!colCenter}>
        <HeaderDesktopContentStyles.ColStart>
          {colStart}
        </HeaderDesktopContentStyles.ColStart>
        {colCenter ? (
          <HeaderDesktopContentStyles.ColCenter>
            {colCenter}
          </HeaderDesktopContentStyles.ColCenter>
        ) : null}
        <HeaderDesktopContentStyles.ColEnd is2Col={!colCenter}>
          {colEnd}
        </HeaderDesktopContentStyles.ColEnd>
      </HeaderDesktopContentStyles.Row>
    </HeaderDesktopContentStyles.Root>
  );
}
