import {
  HeaderDesktopContentStyles,
  HeaderDesktopContentTriple,
} from './HeaderDesktopContent';
import HeaderDesktopLink from './HeaderDesktopLink';
import {
  CREATE_FINANCIAL_STRATEGY_URL,
  EVERYDAY_FINANCES_URL,
  FEATURED_URL,
  GIVE_BACK_URL,
  INVESTMENT_DECISIONS_URL,
  PROTECT_WHATS_IMPORTANT_URL,
  SAVE_FOR_RETIREMENT_URL,
} from './HeaderUrlConstants';
import styled from 'styled-components';

const HeaderDesktopContentNeedsStyles = {
  List: styled.ul`
    column-count: 2;
    column-gap: 1em;
    > li {
      margin-bottom: 1.5rem;
    }
  `,
};

function RightArrow(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1.2em"
      height="1.2em"
      viewBox="0 -960 960 960"
      focusable="false"
      aria-hidden
    >
      <path d="M686-450H190q-13 0-21.5-8.5T160-480q0-13 8.5-21.5T190-510h496L459-737q-9-9-9-21t9-21q9-9 21-9t21 9l278 278q5 5 7 10t2 11q0 6-2 11t-7 10L501-181q-9 9-21 9t-21-9q-9-9-9-21t9-21l227-227Z" />
    </svg>
  );
}

export default function HeaderDesktopContentNeeds(
  props: Omit<React.ComponentPropsWithoutRef<'div'>, 'children'>,
) {
  return (
    <HeaderDesktopContentTriple
      {...props}
      colStart={
        <HeaderDesktopContentStyles.OverviewRoot>
          <HeaderDesktopContentStyles.OverviewTitle>
            Your Needs
          </HeaderDesktopContentStyles.OverviewTitle>
          <HeaderDesktopContentStyles.OverviewDescription>
            We help clients gain financial security and peace of mind from
            knowing they&rsquo;re protecting what matters most in their lives.
          </HeaderDesktopContentStyles.OverviewDescription>
        </HeaderDesktopContentStyles.OverviewRoot>
      }
      colCenter={
        <HeaderDesktopContentNeedsStyles.List>
          <HeaderDesktopLink
            title="Create a financial strategy"
            description="Bring your financial future into focus with personalized advice."
            href={CREATE_FINANCIAL_STRATEGY_URL}
            data-analytics-name="your-needs/create-a-financial-strategy"
          />
          <HeaderDesktopLink
            title="Manage everyday finances"
            description="Successfully managing day-to-day finances plays an essential role in your financial strategy."
            href={EVERYDAY_FINANCES_URL}
            data-analytics-name="your-needs/manage-day-to-day-finances"
          />
          <HeaderDesktopLink
            title="Save & build for retirement"
            description="Build a retirement that&rsquo;s as unique as you are—with guidance and tools that put you in control."
            href={SAVE_FOR_RETIREMENT_URL}
            data-analytics-name="your-needs/save-for-retirement"
          />
          <HeaderDesktopLink
            title="Make investment decisions"
            description="Pursue your growth goals following priority-driven strategies."
            href={INVESTMENT_DECISIONS_URL}
            data-analytics-name="your-needs/make-investment-decisions"
          />
          <HeaderDesktopLink
            title="Protect what&rsquo;s important"
            description="No one can predict the future, but we can help you prepare for any of life&rsquo;s unexpected surprises."
            href={PROTECT_WHATS_IMPORTANT_URL}
            data-analytics-name="your-needs/protect-whats-important"
          />
          <HeaderDesktopLink
            title="Give back"
            description="We amplify our clients&rsquo; incredible generosity through charitable giving programs and volunteer opportunities."
            href={GIVE_BACK_URL}
            data-analytics-name="your-needs/generosity"
          />
        </HeaderDesktopContentNeedsStyles.List>
      }
      colEnd={
        <HeaderDesktopContentStyles.FeatureRoot>
          <HeaderDesktopContentStyles.FeatureText>
            Featured
          </HeaderDesktopContentStyles.FeatureText>
          <HeaderDesktopContentStyles.FeatureContent>
            <HeaderDesktopContentStyles.FeatureTitle>
              What makes Thrivent unlike any other financial services
              organization
            </HeaderDesktopContentStyles.FeatureTitle>
            <HeaderDesktopContentStyles.FeatureDescription>
              We strive to help you achieve financial clarity &mdash; the first
              step to achieving your goals.
            </HeaderDesktopContentStyles.FeatureDescription>
          </HeaderDesktopContentStyles.FeatureContent>
          <div>
            <HeaderDesktopContentStyles.FeatureLink
              href={FEATURED_URL}
              aria-label="Learn more about the thrivent difference"
              data-analytics-name="your-needs/learn-more-about-the-thrivent-difference"
            >
              Learn more <RightArrow />
            </HeaderDesktopContentStyles.FeatureLink>
          </div>
        </HeaderDesktopContentStyles.FeatureRoot>
      }
    />
  );
}
