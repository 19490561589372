import { forwardRef } from 'react';

export interface LogoProps
  extends Omit<
    React.SVGProps<SVGSVGElement>,
    'xmlns' | 'width' | 'height' | 'viewBox' | 'fill'
  > {
  /** Sets the `class` attribute. */
  className?: string | undefined;
  /** Sets the inverse logo colors. */
  inverse?: boolean | undefined;
}

const Logo = forwardRef<SVGSVGElement, LogoProps>(
  ({ inverse = false, ...props }, forwardedRef) => {
    return (
      <svg
        {...props}
        xmlns="http://www.w3.org/2000/svg"
        width="170"
        height="37"
        viewBox="0 0 170 37"
        fill={inverse ? '#ffffff' : '#000000'}
        ref={forwardedRef}
      >
        <title>Thrivent logo</title>
        <path
          fill="#BE1D3D"
          d="M155.526 10.211V6.507c0-1.631-1.518-2.955-3.15-2.955s-3.15 1.324-3.15 2.955v9.26h6.3v-5.556"
        />
        <path
          fill="#E30045"
          d="M154.782 15.768h3.704c1.632 0 2.955-1.518 2.955-3.15s-1.323-3.15-2.955-3.15h-9.26v6.3h5.556"
        />
        <path d="M0 3.544v23.181c0 3.248.656 5.611 1.969 7.088 1.311 1.476 3.723 2.214 7.234 2.214h2.708v-6.152H9.794c-1.412 0-2.346-.278-2.805-.836-.46-.558-.689-1.527-.689-2.905V15.356h6.496V9.942H6.201V3.544H0M29.308 9.204c-1.28 0-2.527.328-3.74.984-1.215.657-2.084 1.411-2.609 2.264h-.098V.098h-6.398v35.93h6.398v-16.39c0-1.346.385-2.437 1.157-3.273.77-.837 1.894-1.255 3.37-1.255 1.542 0 2.7.467 3.47 1.402.771.936 1.157 2.125 1.157 3.569v15.946h6.399V18.653c0-2.887-.821-5.184-2.461-6.89-1.641-1.706-3.856-2.56-6.645-2.56" />
        <path d="M51.188 10.335a5.727 5.727 0 0 0-2.19 2.363H48.9V9.942h-6.055v26.085h6.399V21.705c0-3.97 1.493-5.956 4.479-5.956.623 0 1.295.132 2.017.395l.985-6.202a5.87 5.87 0 0 0-2.363-.492 5.978 5.978 0 0 0-3.174.885" />
        <path d="M59.91 36.027h6.398V9.942H59.91zM59.91 6.152h6.398V.098H59.91z" />
        <path d="M82.486 26.724h-.098L75.891 9.942h-6.645L79.68 36.027h5.414L95.528 9.942h-6.546l-6.496 16.782M107.562 9.204c-3.478 0-6.358 1.255-8.638 3.765-2.28 2.51-3.42 5.849-3.42 10.015 0 4.168 1.148 7.506 3.445 10.016 2.296 2.51 5.267 3.765 8.909 3.765 4.528 0 8.12-1.935 10.778-5.807l-4.478-3.938c-.854 1.248-1.765 2.24-2.732 2.978-.968.739-2.19 1.107-3.667 1.107-1.74 0-3.109-.533-4.11-1.6-1-1.065-1.55-2.6-1.649-4.601h17.522v-1.92c0-4.166-1.124-7.505-3.371-10.015-2.248-2.51-5.111-3.765-8.59-3.765m-5.56 10.926c.13-1.509.696-2.773 1.697-3.79 1.001-1.017 2.29-1.526 3.864-1.526 1.706 0 3.052.468 4.036 1.403.984.935 1.558 2.24 1.722 3.913H102M135.862 9.204a8.691 8.691 0 0 0-3.937.935c-1.248.624-2.232 1.395-2.954 2.313h-.099v-2.51h-6.152v26.085h6.4V20.081c0-1.509.442-2.715 1.328-3.618.886-.901 2.116-1.353 3.691-1.353 1.673 0 2.855.452 3.544 1.353.689.903 1.033 2.257 1.033 4.06v15.504h6.398V18.95c0-3.052-.82-5.439-2.46-7.161-1.642-1.723-3.905-2.584-6.792-2.584M155.526 15.768h-6.3v10.957c0 3.248.656 5.611 1.969 7.088 1.31 1.476 3.723 2.214 7.234 2.214h2.707v-6.152h-2.116c-1.412 0-2.346-.278-2.806-.836-.46-.558-.688-1.527-.688-2.905V15.768" />
        <path d="M166.59 12.004c.377 0 .61-.162.61-.503 0-.377-.233-.52-.61-.52h-.485v1.023h.485Zm-1.239-1.687h1.31c.826 0 1.31.43 1.31 1.184 0 .538-.34.951-.807 1.13l.808 1.436h-.808l-.754-1.364h-.305v1.364h-.754v-3.75Zm3.697 1.884c0-1.454-1.112-2.584-2.566-2.584-1.436 0-2.53 1.13-2.53 2.584 0 1.436 1.094 2.566 2.53 2.566 1.454 0 2.566-1.13 2.566-2.566Zm-5.707 0c0-1.813 1.364-3.177 3.141-3.177 1.812 0 3.177 1.364 3.177 3.177 0 1.777-1.365 3.14-3.177 3.14-1.777 0-3.14-1.363-3.14-3.14Z" />
      </svg>
    );
  },
);

Logo.displayName = 'Logo';

export default Logo;
