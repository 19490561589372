import { showCreditUnionModalAtom } from '../CreditUnionModal';
import {
  HeaderDesktopContentStyles,
  HeaderDesktopContentTriple,
} from './HeaderDesktopContent';
import {
  ADVICE_URL,
  ALL_INVESTMENTS_URL,
  ANNUITIES_URL,
  BANKING_URL,
  DEDICATED_PLANNING_URL,
  DISABILITY_INSURANCE_URL,
  GENEROSITY_URL,
  INSURANCE_URL,
  LIFE_INSURANCE_URL,
  LONG_TERM_CARE_INSURANCE_URL,
  MANAGED_ACCOUNTS_URL,
  MULTI_YEAR_URL,
  MUTUAL_FUNDS_URL,
  PERSONAL_TRUST_URL,
  PRODUCT_BASED_SOLUTIONS_URL,
} from './HeaderUrlConstants';
import * as NavigationMenu from '@radix-ui/react-navigation-menu';
import { useAtom } from 'jotai';
import styled from 'styled-components';

export const HeaderDesktopContentSolutionsStyles = {
  Root: styled.ul`
    font-size: 1rem;
    margin: 0;
    column-count: 2;
    column-gap: 1em;

    button {
      border: none;
      padding: 0;
      background: none;
      font-size: inherit;
      cursor: pointer;
    }
    a,
    button {
      text-decoration: none;
      color: ${(p) => p.theme.midwestColors.textPrimary};

      &:focus,
      &:hover {
        color: ${(p) => p.theme.midwestColors.identityBrandTertiaryStrong};
      }
    }
  `,
  RootItem: styled.li`
    break-inside: avoid-column;
    &:not(:last-child) {
      margin-bottom: 1em;
    }
  `,
  Title: styled.div`
    font-weight: 630;
    margin-bottom: 1.5rem;
  `,
  List: styled.ul`
    margin: 0.375em 0 0;
    > li {
      &:not(:last-child) {
        margin-bottom: 1rem;
      }
      &:last-child {
        margin-bottom: 2rem;
      }
    }
  `,
};

function RightArrow(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1.2em"
      height="1.2em"
      viewBox="0 -960 960 960"
      focusable="false"
      aria-hidden
    >
      <path d="M686-450H190q-13 0-21.5-8.5T160-480q0-13 8.5-21.5T190-510h496L459-737q-9-9-9-21t9-21q9-9 21-9t21 9l278 278q5 5 7 10t2 11q0 6-2 11t-7 10L501-181q-9 9-21 9t-21-9q-9-9-9-21t9-21l227-227Z" />
    </svg>
  );
}

export default function HeaderDesktopContentSolutions(
  props: Omit<React.ComponentPropsWithoutRef<'div'>, 'children'>,
) {
  const [, setOpen] = useAtom(showCreditUnionModalAtom);
  return (
    <HeaderDesktopContentTriple
      {...props}
      colStart={
        <HeaderDesktopContentStyles.OverviewRoot>
          <HeaderDesktopContentStyles.OverviewTitle>
            Our solutions
          </HeaderDesktopContentStyles.OverviewTitle>
          <HeaderDesktopContentStyles.OverviewDescription>
            We provide advice centered around you with product solutions and
            services designed to meet your financial needs and goals.
          </HeaderDesktopContentStyles.OverviewDescription>
        </HeaderDesktopContentStyles.OverviewRoot>
      }
      colCenter={
        <HeaderDesktopContentSolutionsStyles.Root>
          <HeaderDesktopContentSolutionsStyles.RootItem>
            <HeaderDesktopContentSolutionsStyles.Title>
              Advice services
            </HeaderDesktopContentSolutionsStyles.Title>
            <HeaderDesktopContentSolutionsStyles.List>
              <li>
                <NavigationMenu.Link asChild>
                  <a
                    href={PRODUCT_BASED_SOLUTIONS_URL}
                    data-analytics-name="our-solutions/product-based-solutions"
                  >
                    Product-based solutions
                  </a>
                </NavigationMenu.Link>
              </li>
              <li>
                <NavigationMenu.Link asChild>
                  <a
                    href={DEDICATED_PLANNING_URL}
                    data-analytics-name="our-solutions/financial-planning"
                  >
                    Dedicated planning
                  </a>
                </NavigationMenu.Link>
              </li>
              <li>
                <NavigationMenu.Link asChild>
                  <a
                    href={PERSONAL_TRUST_URL}
                    data-analytics-name="our-solutions/trust-services"
                  >
                    Personal trust
                  </a>
                </NavigationMenu.Link>
              </li>
              <li>
                <NavigationMenu.Link asChild>
                  <a
                    href={ADVICE_URL}
                    data-analytics-name="our-solutions/advice"
                  >
                    All advice services
                  </a>
                </NavigationMenu.Link>
              </li>
            </HeaderDesktopContentSolutionsStyles.List>
          </HeaderDesktopContentSolutionsStyles.RootItem>
          <HeaderDesktopContentSolutionsStyles.RootItem>
            <HeaderDesktopContentSolutionsStyles.Title>
              Insurance
            </HeaderDesktopContentSolutionsStyles.Title>
            <HeaderDesktopContentSolutionsStyles.List>
              <li>
                <NavigationMenu.Link asChild>
                  <a
                    href={LIFE_INSURANCE_URL}
                    data-analytics-name="our-solutions/life-insurance"
                  >
                    Life insurance
                  </a>
                </NavigationMenu.Link>
              </li>
              <li>
                <NavigationMenu.Link asChild>
                  <a
                    href={DISABILITY_INSURANCE_URL}
                    data-analytics-name="our-solutions/disability-insurance"
                  >
                    Disability insurance
                  </a>
                </NavigationMenu.Link>
              </li>
              <li>
                <NavigationMenu.Link asChild>
                  <a
                    href={LONG_TERM_CARE_INSURANCE_URL}
                    data-analytics-name="our-solutions/long-term-care-insurance-select-state"
                  >
                    Long-term care insurance
                  </a>
                </NavigationMenu.Link>
              </li>
              <li>
                <NavigationMenu.Link asChild>
                  <a
                    href={INSURANCE_URL}
                    data-analytics-name="our-solutions/insurance"
                  >
                    All insurance
                  </a>
                </NavigationMenu.Link>
              </li>
            </HeaderDesktopContentSolutionsStyles.List>
          </HeaderDesktopContentSolutionsStyles.RootItem>
          <HeaderDesktopContentSolutionsStyles.RootItem>
            <HeaderDesktopContentSolutionsStyles.Title>
              Investments
            </HeaderDesktopContentSolutionsStyles.Title>
            <HeaderDesktopContentSolutionsStyles.List>
              <li>
                <NavigationMenu.Link asChild>
                  <a
                    href={MUTUAL_FUNDS_URL}
                    data-analytics-name="our-solutions/mutual-funds"
                  >
                    Mutual funds
                  </a>
                </NavigationMenu.Link>
              </li>
              <li>
                <NavigationMenu.Link asChild>
                  <a
                    href={MANAGED_ACCOUNTS_URL}
                    data-analytics-name="our-solutions/managed-accounts"
                  >
                    Managed accounts
                  </a>
                </NavigationMenu.Link>
              </li>
              <li>
                <NavigationMenu.Link asChild>
                  <a
                    href={ALL_INVESTMENTS_URL}
                    data-analytics-name="our-solutions/investments"
                  >
                    All investments
                  </a>
                </NavigationMenu.Link>
              </li>
            </HeaderDesktopContentSolutionsStyles.List>
          </HeaderDesktopContentSolutionsStyles.RootItem>
          <HeaderDesktopContentSolutionsStyles.RootItem>
            <HeaderDesktopContentSolutionsStyles.Title>
              <NavigationMenu.Link asChild>
                <a
                  href={ANNUITIES_URL}
                  data-analytics-name="our-solutions/annuities"
                >
                  Annuities
                </a>
              </NavigationMenu.Link>
            </HeaderDesktopContentSolutionsStyles.Title>
          </HeaderDesktopContentSolutionsStyles.RootItem>
          <HeaderDesktopContentSolutionsStyles.RootItem>
            <HeaderDesktopContentSolutionsStyles.Title>
              Generosity
            </HeaderDesktopContentSolutionsStyles.Title>
            <HeaderDesktopContentSolutionsStyles.List>
              <li>
                <NavigationMenu.Link asChild>
                  <a
                    href="https://www.thriventcharitable.com/"
                    data-analytics-name="about-us/charitable"
                    target="_blank"
                  >
                    Thrivent Charitable
                  </a>
                </NavigationMenu.Link>
              </li>
              <li>
                <NavigationMenu.Link asChild>
                  <a
                    href={GENEROSITY_URL}
                    data-analytics-name="our-solutions/generosity"
                  >
                    All Generosity
                  </a>
                </NavigationMenu.Link>
              </li>
            </HeaderDesktopContentSolutionsStyles.List>
          </HeaderDesktopContentSolutionsStyles.RootItem>
          <HeaderDesktopContentSolutionsStyles.RootItem>
            <HeaderDesktopContentSolutionsStyles.Title>
              Banking
            </HeaderDesktopContentSolutionsStyles.Title>
            <HeaderDesktopContentSolutionsStyles.List>
              <li>
                <NavigationMenu.Link asChild>
                  <a
                    href="https://www.thriventchurchloans.com"
                    data-analytics-name="our-solutions/church-loans"
                  >
                    Church Loans
                  </a>
                </NavigationMenu.Link>
              </li>
              <li>
                <NavigationMenu.Link asChild>
                  <button onClick={() => setOpen(true)}>Credit Union</button>
                </NavigationMenu.Link>
              </li>
              <li>
                <NavigationMenu.Link asChild>
                  <a
                    href={BANKING_URL}
                    data-analytics-name="our-solutions/banking"
                  >
                    All Banking
                  </a>
                </NavigationMenu.Link>
              </li>
            </HeaderDesktopContentSolutionsStyles.List>
          </HeaderDesktopContentSolutionsStyles.RootItem>
        </HeaderDesktopContentSolutionsStyles.Root>
      }
      colEnd={
        <HeaderDesktopContentStyles.FeatureRoot>
          <HeaderDesktopContentStyles.FeatureText>
            Featured
          </HeaderDesktopContentStyles.FeatureText>
          <HeaderDesktopContentStyles.FeatureContent>
            <HeaderDesktopContentStyles.FeatureTitle>
              Explore Multi-Year Guarantee Annuities
            </HeaderDesktopContentStyles.FeatureTitle>
            <HeaderDesktopContentStyles.FeatureDescription>
              A Multi&mdash;Year Guarantee Annuity (MYGA) lets your money grow
              at a fixed interest rate for a pre-determined number of years.
              Learn more on whether a MYGA could help provide balance to your
              portfolio.
            </HeaderDesktopContentStyles.FeatureDescription>
          </HeaderDesktopContentStyles.FeatureContent>
          <div>
            <HeaderDesktopContentStyles.FeatureLink
              href={MULTI_YEAR_URL}
              aria-label="Learn more about the Thrivent Multi-Year Guarantee Annuities"
              data-analytics-name="our-solutions/multi-year-guarantee-annuities"
            >
              Learn more <RightArrow />
            </HeaderDesktopContentStyles.FeatureLink>
          </div>
        </HeaderDesktopContentStyles.FeatureRoot>
      }
    />
  );
}
