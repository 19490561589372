import { BannerEmergency } from '../banner-emergency/banner-emergency';
import { LandmarkLink } from '@exploration/shared-components';
import { GlobalData } from '@exploration/shared-types';
import { Footer, Header } from '@thrivent/thrivent-public-header-footer';

export interface LayoutProps {
  /** Sets the content. **Required** */
  children: React.ReactNode;
  globalData: GlobalData;
  hideLoginButton?: boolean;
  hideFAButton?: boolean;
}

export function Layout({
  children,
  globalData,
  hideLoginButton,
  hideFAButton,
}: LayoutProps) {
  return (
    <>
      <LandmarkLink id="content-skip-link" href="#content">
        Skip to main content
      </LandmarkLink>

      <Header hideLoginButton={hideLoginButton} hideFAButton={hideFAButton} />

      {globalData?.bannerMessages && (
        <BannerEmergency banners={globalData?.bannerMessages} />
      )}

      <LandmarkLink id="content" href="#content">
        Start of main content
      </LandmarkLink>

      {children}

      <Footer />
    </>
  );
}
