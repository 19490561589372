import { Logo } from '../Logo';
import { HeaderProps } from './HeaderDesktop';
import styled from 'styled-components';

const FallbackHeaderStyles = {
  Header: styled.header`
    height: 6.12rem;
    display: flex;
    align-items: center;
    max-width: 78rem;
    margin-inline: auto;
    padding-inline: 1rem;
  `,
  LogoLink: styled.a`
    display: block;
    width: 10.25rem; /* Resizes svg to roughly 165x36px, height being the most important. */
  `,
};

const thriventBaseUrl: string = process.env[
  'NEXT_PUBLIC_THRIVENT_BASE_URL'
] as string;

export default function FallbackHeader(props: Omit<HeaderProps, 'logInOrOut'>) {
  return (
    <FallbackHeaderStyles.Header {...props}>
      <FallbackHeaderStyles.LogoLink href={`${thriventBaseUrl}/`}>
        <Logo />
      </FallbackHeaderStyles.LogoLink>
    </FallbackHeaderStyles.Header>
  );
}
