'use client';

/*
todo redo this page to be more like the other pages in app router

This is a quick copy of the page router 404.tsx but it would be good to
redo this page to be more like the other pages in app router, i.e. not
require 'use client' immediately
 */
import { ErrorPage } from '@exploration/pages-error';
import { Layout } from '@exploration/shared-sections';

export default function NotFound() {
  return (
    <Layout globalData={null}>
      <ErrorPage />
    </Layout>
  );
}
