import { NEXT_PUBLIC_THRIVENT_BASE_URL } from '../../constants/env';
import { Container } from '../Container';
import {
  CreditUnionModal,
  showCreditUnionModalAtom,
} from '../CreditUnionModal';
import { Logo } from '../Logo';
import { LogoBrokerCheckByFinra } from '../LogoBrokerCheckByFinra';
import { useLicensePlate } from '@thrivent-web/license-plate';
import { useAtom } from 'jotai';
import { forwardRef } from 'react';
import styled, { css } from 'styled-components';

declare global {
  interface Window {
    OneTrust: {
      ToggleInfoDisplay: () => void;
    };
  }
}

const FooterStyles = {
  Root: styled.footer`
    font-size: 0.875rem /* 14px */;
    line-height: 1.25rem /* 20px */;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 3rem 0;
    background-color: ${(p) => p.theme.midwestColors.neutralDarkHeavy};
    color: ${(p) => p.theme.midwestColors.neutralLightWeak};
    @media (min-width: 1280px) {
      gap: 2.5rem /* 40px */;
      padding: 6rem 0;
    }
  `,
  LogoLink: styled.a`
    display: block;
    width: 110px;
    svg {
      width: 100%;
      height: auto;
    }
    @media screen and (min-width: 1280px) {
      width: 165px;
    }
  `,
  Divider: styled.div`
    height: 1px;
    background-color: ${(p) => p.theme.midwestColors.neutralDarkStrong};
  `,
};

const LinkStyles = css`
  text-decoration: none;
  color: ${(p) => p.theme.midwestColors.neutralLightWeak};
  font-size: 1em;

  &:hover,
  &:focus {
    text-decoration: underline;
  }
`;

const NavigationStyles = {
  Root: styled.div`
    display: flex;
    flex-direction: column;
    gap: 2rem;
    @media screen and (min-width: 1280px) {
      display: grid;
      grid-template-columns: repeat(6, minmax(0, 1fr));
    }
  `,
  Title: styled.div`
    font-size: 1rem /* 16px */;
    line-height: 1.5rem /* 24px */;
    font-weight: 700;
    margin-bottom: 1rem /* 16px */;
    color: ${(p) => p.theme.midwestColors.neutralLightWeak};
  `,
  List: styled.ul`
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    margin: 0;
    padding: 0;
  `,
  Anchor: styled.a`
    ${LinkStyles}
  `,
  Button: styled.button`
    ${LinkStyles}
    font-family: Inter, sans-serif;
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    &:hover,
    &:focus {
      text-decoration: underline;
    }
  `,
  ListFollowUs: styled.div`
    @media screen and (min-width: 1280px) {
      grid-column-start: 2;
      grid-row-start: 2;
      /* Special number to be aligned to text line */
      margin-top: -7.25rem;
    }
  `,
  IconList: styled.ul`
    list-style: none;
    display: flex;
    align-items: center;
    gap: 1rem;
    margin: 0;
    padding: 0;
  `,
  Icon: styled.svg`
    font-size: 2rem;
    line-height: 2.5rem;
    align-self: center;
    &:hover,
    &:focus {
      color: ${(p) => p.theme.midwestColors.neutralLightWeak};
    }
  `,
  SocialAnchor: styled.a`
    display: block;
    color: ${(p) => p.theme.midwestColors.neutralLightWeak};
  `,
  SpacerCol: styled.div`
    display: none;
    @media screen and (min-width: 1280px) {
      display: block;
    }
  `,
  PhoneWrapper: styled.div`
    margin-top: 0.75rem;
  `,
  PhoneAnchor: styled.a`
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: ${(p) => p.theme.midwestColors.neutralLightWeak};
    &,
    &:hover,
    &:focus {
      text-decoration: none;
    }
  `,
};

const DisclaimerStyles = {
  Root: styled.div`
    display: flex;
    flex-direction: column;
    gap: 2rem;
    font-size: 0.75rem /* 12px */;
    line-height: 1rem /* 16px */;
    @media screen and (min-width: 1280px) {
      flex-direction: row;
      justify-content: space-between;
    }
  `,
  ColA: styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    @media screen and (min-width: 1280px) {
      width: 48rem;
    }
  `,
  Anchor: styled.a`
    font-size: 0.75rem /* 12px */;
    line-height: 1rem /* 16px */;
    color: ${(p) => p.theme.midwestColors.textLinkDarkBG};
    text-decoration: underline;
  `,
  ColB: styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    width: 15rem;
    @media screen and (min-width: 1280px) {
      text-align: right;
    }
  `,
  BrokerWrapper: styled.div`
    @media screen and (min-width: 1280px) {
      align-self: flex-end;
    }
  `,
  BrokerAnchor: styled.a`
    display: block;
    width: 139px;
  `,
  BrokerLogo: styled(LogoBrokerCheckByFinra)`
    width: 100%;
    height: auto;
    fill: ${(p) => p.theme.midwestColors.textDisabled};
  `,
};

function LicensePlate() {
  const licensePlate = useLicensePlate();
  const formattedLicensePlate = licensePlate?.replace(
    /(\w{1})(\w{3})(\w{3})/,
    '$1-$2-$3',
  );

  return <p>{formattedLicensePlate}</p>;
}

export interface FooterProps
  extends Omit<
    React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>,
    'children'
  > {
  /** Sets the `class` attribute. */
  className?: string | undefined;
  baseUrl?: string;
  // Since one trust needs to be registered on a per app basis, we'll allow it to be toggled on/off here
  showCookieSettings?: boolean;
  // Allow apps to decide to use the Datadog license plate or not since it will be picked up by OneTrust
  showLicensePlate?: boolean;
  // Since the login link serves little purpose for the login app, we'll allow it to be toggled on/off here
  showLoginLink?: boolean;
}

const thriventBaseUrl = NEXT_PUBLIC_THRIVENT_BASE_URL || '';

const Footer = forwardRef<HTMLElement, FooterProps>(
  (
    {
      baseUrl = thriventBaseUrl,
      showCookieSettings = true,
      showLoginLink = true,
      showLicensePlate = false,
    },
    forwardedRef,
  ) => {
    const [, setOpen] = useAtom(showCreditUnionModalAtom);

    return (
      <>
        <CreditUnionModal />
        <FooterStyles.Root role="contentinfo" ref={forwardedRef}>
          <Container>
            <FooterStyles.LogoLink href={`${baseUrl}/`}>
              <Logo inverse />
            </FooterStyles.LogoLink>
          </Container>

          <Container>
            <NavigationStyles.Root>
              <div>
                <NavigationStyles.Title>Navigation</NavigationStyles.Title>
                <NavigationStyles.List>
                  <li>
                    <NavigationStyles.Anchor href={`${baseUrl}/about-us`}>
                      About us
                    </NavigationStyles.Anchor>
                  </li>
                  <li>
                    <NavigationStyles.Anchor href={`${baseUrl}/insights`}>
                      Insights & guidance
                    </NavigationStyles.Anchor>
                  </li>
                  {showLoginLink && (
                    <li>
                      <NavigationStyles.Anchor
                        href={process.env['NEXT_PUBLIC_SERVICING_URL']}
                      >
                        Log in
                      </NavigationStyles.Anchor>
                    </li>
                  )}
                  <li>
                    <NavigationStyles.Anchor
                      href="https://local.thrivent.com"
                      data-analytics-cta-text="Find a financial advisor Footer CTA"
                    >
                      Find a Financial Advisor
                    </NavigationStyles.Anchor>
                  </li>
                  <li>
                    <NavigationStyles.Anchor href="https://careers.thrivent.com">
                      Careers
                    </NavigationStyles.Anchor>
                  </li>
                  <li>
                    <NavigationStyles.Anchor
                      href={`${baseUrl}/about-us/support`}
                    >
                      Contact us
                    </NavigationStyles.Anchor>
                  </li>
                  <li>
                    <NavigationStyles.Anchor href={`${baseUrl}/claims`}>
                      Claims
                    </NavigationStyles.Anchor>
                  </li>
                  <li>
                    <NavigationStyles.Anchor
                      href="https://www.mybrandmall.com/Thrivent"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Thrivent merchandise
                    </NavigationStyles.Anchor>
                  </li>
                  <li>
                    <NavigationStyles.Anchor
                      href={`${baseUrl}/client-resources`}
                    >
                      Client resources
                    </NavigationStyles.Anchor>
                  </li>
                </NavigationStyles.List>
              </div>
              <div>
                <NavigationStyles.Title>Quick Links</NavigationStyles.Title>
                <NavigationStyles.List>
                  <li>
                    <NavigationStyles.Anchor
                      href={`${baseUrl}/tools-and-calculators`}
                    >
                      Tools & calculators
                    </NavigationStyles.Anchor>
                  </li>
                  <li>
                    <NavigationStyles.Anchor href="https://newsroom.thrivent.com">
                      Newsroom
                    </NavigationStyles.Anchor>
                  </li>
                  <li>
                    <NavigationStyles.Anchor
                      href={`${baseUrl}/governance#financial-info`}
                    >
                      Financial information
                    </NavigationStyles.Anchor>
                  </li>
                  <li>
                    <NavigationStyles.Anchor
                      href={`${baseUrl}/insights/magazine`}
                    >
                      Thrivent Magazine
                    </NavigationStyles.Anchor>
                  </li>
                  <li>
                    <NavigationStyles.Anchor href={`${baseUrl}/mobile-app`}>
                      Mobile app
                    </NavigationStyles.Anchor>
                  </li>
                </NavigationStyles.List>
              </div>
              <div>
                <NavigationStyles.Title>Related Sites</NavigationStyles.Title>
                <NavigationStyles.List>
                  <li>
                    <NavigationStyles.Button onClick={() => setOpen(true)}>
                      Thrivent Credit Union
                    </NavigationStyles.Button>
                  </li>
                  <li>
                    <NavigationStyles.Anchor
                      target="_blank"
                      href="https://www.thriventchurchloans.com"
                    >
                      Thrivent Church Loans
                    </NavigationStyles.Anchor>
                  </li>
                  <li>
                    <NavigationStyles.Anchor
                      href="https://www.thriventcharitable.com/"
                      target="_blank"
                    >
                      Thrivent Charitable
                    </NavigationStyles.Anchor>
                  </li>
                  <li>
                    <NavigationStyles.Anchor
                      href="https://www.newmanlongtermcare.com"
                      target="_blank"
                    >
                      Newman Long Term Care
                    </NavigationStyles.Anchor>
                  </li>
                  <li>
                    <NavigationStyles.Anchor
                      href="https://www.thriventinsuranceagency.com"
                      target="_blank"
                    >
                      Thrivent Insurance Agency
                    </NavigationStyles.Anchor>
                  </li>
                  <li>
                    <NavigationStyles.Anchor
                      href="https://thriventprivateinvestments.com"
                      target="_blank"
                    >
                      Private Investments
                    </NavigationStyles.Anchor>
                  </li>
                </NavigationStyles.List>
              </div>
              <div>
                <NavigationStyles.Title>Legal</NavigationStyles.Title>
                <NavigationStyles.List>
                  <li>
                    <NavigationStyles.Anchor href={`${baseUrl}/forms`}>
                      Forms
                    </NavigationStyles.Anchor>
                  </li>
                  <li>
                    <NavigationStyles.Anchor href={`${baseUrl}/governance`}>
                      Governance
                    </NavigationStyles.Anchor>
                  </li>
                  <li>
                    <NavigationStyles.Anchor
                      href={`${baseUrl}/privacy-and-security`}
                      style={{ textTransform: 'uppercase' }}
                    >
                      Privacy & Security
                    </NavigationStyles.Anchor>
                  </li>
                  {showCookieSettings && (
                    <li>
                      <NavigationStyles.Button
                        onClick={() => window?.OneTrust.ToggleInfoDisplay()}
                        aria-label="cookie settings"
                      >
                        Cookie Settings
                      </NavigationStyles.Button>
                    </li>
                  )}
                  <li>
                    <NavigationStyles.Anchor
                      href={`${baseUrl}/tax-resource-center`}
                    >
                      Tax resource center
                    </NavigationStyles.Anchor>
                  </li>
                  <li>
                    <NavigationStyles.Anchor
                      href={`${baseUrl}/privacy-and-security/terms-of-use`}
                    >
                      Terms of use
                    </NavigationStyles.Anchor>
                  </li>
                </NavigationStyles.List>
              </div>
              <NavigationStyles.ListFollowUs>
                <NavigationStyles.Title>Follow Us</NavigationStyles.Title>
                <NavigationStyles.IconList>
                  <li>
                    <NavigationStyles.SocialAnchor
                      href="https://www.facebook.com/thrivent"
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label="Facebook"
                    >
                      <NavigationStyles.Icon
                        fill="#FFF"
                        strokeWidth="0"
                        viewBox="0 0 24 24"
                        focusable="false"
                        aria-hidden="true"
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                        aria-label="Facebook Icon"
                      >
                        <g>
                          <path fill="none" d="M0 0h24v24H0z"></path>
                          <path d="M12 2C6.477 2 2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.879V14.89h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.989C18.343 21.129 22 16.99 22 12c0-5.523-4.477-10-10-10z"></path>
                        </g>
                      </NavigationStyles.Icon>
                    </NavigationStyles.SocialAnchor>
                  </li>
                  <li>
                    <NavigationStyles.SocialAnchor
                      href="https://www.linkedin.com/company/6233"
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label="LinkedIn"
                    >
                      <NavigationStyles.Icon
                        fill="#FFF"
                        strokeWidth="0"
                        viewBox="0 0 24 24"
                        focusable="false"
                        aria-hidden="true"
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                        aria-label="LinkedIn Icon"
                      >
                        <g>
                          <path fill="none" d="M0 0h24v24H0z"></path>
                          <path
                            fillRule="nonzero"
                            d="M18.335 18.339H15.67v-4.177c0-.996-.02-2.278-1.39-2.278-1.389 0-1.601 1.084-1.601 2.205v4.25h-2.666V9.75h2.56v1.17h.035c.358-.674 1.228-1.387 2.528-1.387 2.7 0 3.2 1.778 3.2 4.091v4.715zM7.003 8.575a1.546 1.546 0 0 1-1.548-1.549 1.548 1.548 0 1 1 1.547 1.549zm1.336 9.764H5.666V9.75H8.34v8.589zM19.67 3H4.329C3.593 3 3 3.58 3 4.297v15.406C3 20.42 3.594 21 4.328 21h15.338C20.4 21 21 20.42 21 19.703V4.297C21 3.58 20.4 3 19.666 3h.003z"
                          ></path>
                        </g>
                      </NavigationStyles.Icon>
                    </NavigationStyles.SocialAnchor>
                  </li>
                  <li>
                    <NavigationStyles.SocialAnchor
                      href="https://twitter.com/thrivent"
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label="X"
                    >
                      <NavigationStyles.Icon
                        fill="#FFF"
                        strokeWidth="0"
                        viewBox="0 0 24 24"
                        focusable="false"
                        aria-hidden="true"
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                        aria-label="X Icon"
                      >
                        <g>
                          <path fill="none" d="M0 0h24v24H0z"></path>
                          <path
                            d="M13.7,10.6L20.2,3h-1.6L13,9.6L8.4,3H3.2l6.9,10l-6.9,8h1.6l6-7l4.8,7h5.2L13.7,10.6L13.7,10.6z M11.5,13.1
	                      l-0.7-1L5.3,4.2h2.4l4.5,6.4l0.7,1l5.8,8.3h-2.4L11.5,13.1L11.5,13.1z"
                          />
                        </g>
                      </NavigationStyles.Icon>
                    </NavigationStyles.SocialAnchor>
                  </li>
                  <li>
                    <NavigationStyles.SocialAnchor
                      href="https://www.instagram.com/thrivent"
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label="Instagram"
                    >
                      <NavigationStyles.Icon
                        fill="#FFF"
                        strokeWidth="0"
                        viewBox="0 0 24 24"
                        focusable="false"
                        aria-hidden="true"
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                        aria-label="Instagram Icon"
                      >
                        <g>
                          <path fill="none" d="M0 0h24v24H0z"></path>
                          <path
                            fillRule="nonzero"
                            d="M12 9a3 3 0 1 0 0 6 3 3 0 0 0 0-6zm0-2a5 5 0 1 1 0 10 5 5 0 0 1 0-10zm6.5-.25a1.25 1.25 0 0 1-2.5 0 1.25 1.25 0 0 1 2.5 0zM12 4c-2.474 0-2.878.007-4.029.058-.784.037-1.31.142-1.798.332-.434.168-.747.369-1.08.703a2.89 2.89 0 0 0-.704 1.08c-.19.49-.295 1.015-.331 1.798C4.006 9.075 4 9.461 4 12c0 2.474.007 2.878.058 4.029.037.783.142 1.31.331 1.797.17.435.37.748.702 1.08.337.336.65.537 1.08.703.494.191 1.02.297 1.8.333C9.075 19.994 9.461 20 12 20c2.474 0 2.878-.007 4.029-.058.782-.037 1.309-.142 1.797-.331.433-.169.748-.37 1.08-.702.337-.337.538-.65.704-1.08.19-.493.296-1.02.332-1.8.052-1.104.058-1.49.058-4.029 0-2.474-.007-2.878-.058-4.029-.037-.782-.142-1.31-.332-1.798a2.911 2.911 0 0 0-.703-1.08 2.884 2.884 0 0 0-1.08-.704c-.49-.19-1.016-.295-1.798-.331C14.925 4.006 14.539 4 12 4zm0-2c2.717 0 3.056.01 4.122.06 1.065.05 1.79.217 2.428.465.66.254 1.216.598 1.772 1.153a4.908 4.908 0 0 1 1.153 1.772c.247.637.415 1.363.465 2.428.047 1.066.06 1.405.06 4.122 0 2.717-.01 3.056-.06 4.122-.05 1.065-.218 1.79-.465 2.428a4.883 4.883 0 0 1-1.153 1.772 4.915 4.915 0 0 1-1.772 1.153c-.637.247-1.363.415-2.428.465-1.066.047-1.405.06-4.122.06-2.717 0-3.056-.01-4.122-.06-1.065-.05-1.79-.218-2.428-.465a4.89 4.89 0 0 1-1.772-1.153 4.904 4.904 0 0 1-1.153-1.772c-.248-.637-.415-1.363-.465-2.428C2.013 15.056 2 14.717 2 12c0-2.717.01-3.056.06-4.122.05-1.066.217-1.79.465-2.428a4.88 4.88 0 0 1 1.153-1.772A4.897 4.897 0 0 1 5.45 2.525c.638-.248 1.362-.415 2.428-.465C8.944 2.013 9.283 2 12 2z"
                          ></path>
                        </g>
                      </NavigationStyles.Icon>
                    </NavigationStyles.SocialAnchor>
                  </li>
                </NavigationStyles.IconList>
              </NavigationStyles.ListFollowUs>

              <div>
                <NavigationStyles.Title>
                  Operations Center
                </NavigationStyles.Title>
                <div>
                  <div>4321 N. Ballard Rd.</div>
                  <div>Appleton, WI</div>
                  <div>54919-0001 USA</div>
                </div>
              </div>
              <div>
                <NavigationStyles.Title>
                  Corporate Center
                </NavigationStyles.Title>
                <div>
                  <div>600 Portland Avenue S.,</div>
                  <div>Suite 100</div>
                  <div>Minneapolis, MN</div>
                  <div>55415-4402 USA</div>
                  <NavigationStyles.PhoneWrapper>
                    <NavigationStyles.PhoneAnchor href="tel:800-847-4836">
                      T: 800-847-4836
                    </NavigationStyles.PhoneAnchor>
                  </NavigationStyles.PhoneWrapper>
                </div>
              </div>
            </NavigationStyles.Root>
          </Container>

          <Container>
            <FooterStyles.Divider />
          </Container>

          <Container>
            <DisclaimerStyles.Root>
              <DisclaimerStyles.ColA>
                <div>
                  &copy;{new Date().getFullYear()} Thrivent. All rights
                  reserved.
                </div>
                <p>
                  Thrivent is the marketing name for Thrivent Financial for
                  Lutherans. Insurance products issued by Thrivent. Not
                  available in all states. Securities and investment advisory
                  services offered through Thrivent Investment Management Inc.,
                  a registered investment adviser, member{' '}
                  <DisclaimerStyles.Anchor
                    href="https://www.finra.org"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    FINRA
                  </DisclaimerStyles.Anchor>{' '}
                  and{' '}
                  <DisclaimerStyles.Anchor
                    href="https://www.sipc.org"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    SIPC
                  </DisclaimerStyles.Anchor>
                  , and a subsidiary of Thrivent. Licensed agent/producer of
                  Thrivent. Registered representative of Thrivent Investment
                  Management, Inc.{' '}
                  <DisclaimerStyles.Anchor
                    href={`${baseUrl}/privacy-and-security/disclosures`}
                  >
                    Thrivent.com/disclosures
                  </DisclaimerStyles.Anchor>
                  .
                </p>
                <p>
                  Insurance products, securities and investment advisory
                  services are provided by appropriately appointed and licensed
                  financial advisors and professionals. Only individuals who are
                  financial advisors are credentialed to provide investment
                  advisory services. Visit{' '}
                  <DisclaimerStyles.Anchor href={`${baseUrl}/`}>
                    Thrivent.com
                  </DisclaimerStyles.Anchor>{' '}
                  or{' '}
                  <DisclaimerStyles.Anchor
                    href="https://brokercheck.finra.org"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    FINRA&rsquo;s BrokerCheck
                  </DisclaimerStyles.Anchor>{' '}
                  for more information about our financial advisors.
                </p>
                <p>
                  Thrivent provides advice and guidance through its Financial
                  Planning Framework that generally includes a review and
                  analysis of a client&rsquo;s financial situation. A client may
                  choose to further their planning engagement with Thrivent
                  through its Dedicated Planning Services (an investment
                  advisory service) that results in written recommendations for
                  a fee.
                </p>
                {showLicensePlate && <LicensePlate />}
              </DisclaimerStyles.ColA>
              <DisclaimerStyles.ColB>
                <DisclaimerStyles.BrokerWrapper>
                  <DisclaimerStyles.BrokerAnchor
                    href="https://brokercheck.finra.org"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <DisclaimerStyles.BrokerLogo inverse />
                  </DisclaimerStyles.BrokerAnchor>
                </DisclaimerStyles.BrokerWrapper>
                <p>
                  Check the background of our professionals on{' '}
                  <DisclaimerStyles.Anchor
                    href="https://brokercheck.finra.org"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    FINRA&rsquo;s
                  </DisclaimerStyles.Anchor>{' '}
                  BrokerCheck.
                </p>
              </DisclaimerStyles.ColB>
            </DisclaimerStyles.Root>
          </Container>
        </FooterStyles.Root>
      </>
    );
  },
);

Footer.displayName = 'Footer';

export default Footer;
