import SpilledMilkImage from './images/spilled-milk.png';
import { Container as uiContainer } from '@exploration/shared-components';
import { MDSText } from '@midwest/web/base';
import { MDSAnchorButton } from '@midwest/web/button';
import { Link } from '@midwest/web/link';
import { tablet } from '@thrivent/midwest-shared';
import Image from 'next/image';
import styled from 'styled-components';

// --------------------------------------------------------------------------

const Main = styled.main`
  background-color: ${(p) => p.theme.midwestColors.backgroundSecondary};
`;

const Container = styled(uiContainer)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  gap: 2rem;
  flex-direction: column;
  padding: 4rem 0;
  ${tablet} {
    flex-direction: row;
  }
`;

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  flex-shrink: 0;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1rem;
`;

const ButtonContainer = styled.div`
  margin-top: 1rem;
`;

const Questions = styled(MDSText.BodyMediumDefault)`
  margin-top: 1rem;
`;

const SUPPORT_URL = 'https://www.thrivent.com/about-us/support';
const HOME_URL = '/';

// --------------------------------------------------------------------------

export const ErrorPage = ({
  title = "Oops! Sorry, we couldn't find that page.",
}: {
  title?: string;
}) => {
  return (
    <Main>
      <Container>
        <ImageContainer>
          <Image
            height={360}
            width={480}
            src={SpilledMilkImage}
            alt="Spilled Milk"
          />
        </ImageContainer>
        <TextContainer>
          <MDSText.HeroXl>{title}</MDSText.HeroXl>
          <MDSText.BodyRegDefault as="ul">
            <li>
              If you followed a link, {"it's"} probably broken. Please get in
              touch with us, and {"we'll"} fix it.
            </li>
            <li>
              If you typed in the web address, please check your spelling.
            </li>
            <li>
              Try accessing the page directly from the{' '}
              <Link href={HOME_URL}>Thrivent homepage</Link>
            </li>
          </MDSText.BodyRegDefault>

          <MDSText.BodyRegDefault as={'p'}>
            Even though you {"didn't"} immediately find what you were looking
            for, you&apos;re in the right place.
          </MDSText.BodyRegDefault>

          <Questions as={'p'}>Still have questions?</Questions>

          <MDSText.BodyRegDefault as={'p'}>
            Click below to connect with us or request more information.
            We&apos;re here for you.
          </MDSText.BodyRegDefault>

          <ButtonContainer>
            <MDSAnchorButton href={SUPPORT_URL}>
              Connect with us
            </MDSAnchorButton>
          </ButtonContainer>
        </TextContainer>
      </Container>
    </Main>
  );
};

// --------------------------------------------------------------------------

export default ErrorPage;
