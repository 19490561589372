import * as NavigationMenu from '@radix-ui/react-navigation-menu';
import styled from 'styled-components';

const HeaderDesktopContentNeedsStyles = {
  Link: styled.a`
    text-decoration: none;
    * > :first-child {
      color: ${(p) => p.theme.midwestColors.textPrimary};
    }
    * > p {
      color: ${(p) => p.theme.midwestColors.textSecondary};
    }
    &:focus > * > :first-child,
    &:hover > * > :first-child {
      color: ${(p) =>
        p.theme.midwestColors.identityBrandTertiaryStrong}; /* Title only */
    }
  `,
  Root: styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.25em;
    margin-bottom: 1em;
  `,
  Title: styled.div`
    font-size: 1rem;
    font-weight: 630;
  `,
  Description: styled.p`
    margin: 0;
    color: ${(p) => p.theme.midwestColors.textSecondary};
  `,
};

export default function HeaderDesktopLink(props: {
  title: string;
  description: string;
  href: string;
}) {
  return (
    <li>
      <NavigationMenu.Link asChild>
        <HeaderDesktopContentNeedsStyles.Link
          href={props.href}
          aria-label={props.title}
        >
          <HeaderDesktopContentNeedsStyles.Root>
            <HeaderDesktopContentNeedsStyles.Title>
              {props.title}
            </HeaderDesktopContentNeedsStyles.Title>
            <HeaderDesktopContentNeedsStyles.Description>
              {props.description}
            </HeaderDesktopContentNeedsStyles.Description>
          </HeaderDesktopContentNeedsStyles.Root>
        </HeaderDesktopContentNeedsStyles.Link>
      </NavigationMenu.Link>
    </li>
  );
}
