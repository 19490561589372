import {
  HeaderDesktopContentStyles,
  HeaderDesktopContentTriple,
} from './HeaderDesktopContent';
import {
  ABOUT_US_URL,
  CLIENT_STORIES_URL,
  CONTACT_US_URL,
  FINANCIALS_URL,
  FREQUENTLY_ASKED_QUESTIONS_URL,
  GENEROSITY_AND_GIVING_URL,
  LEADERSHIP_URL,
  MEMBERSHIP_URL,
  OUR_HISTORY_URL,
  PURPOSE_AND_VALUES_URL,
} from './HeaderUrlConstants';
import * as NavigationMenu from '@radix-ui/react-navigation-menu';
import styled from 'styled-components';

const HeaderDesktopContentAboutStyles = {
  Root: styled.ul`
    font-size: 1rem;
    margin: 0;
    column-count: 2;
    column-gap: 1em;
    li:not(:last-child) {
      margin-bottom: 1em;
    }
    a {
      text-decoration: none;
      color: ${(p) => p.theme.midwestColors.textPrimary};

      &:focus,
      &:hover {
        color: ${(p) => p.theme.midwestColors.identityBrandTertiaryStrong};
      }
    }
  `,
};

function RightArrow(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1.2em"
      height="1.2em"
      viewBox="0 -960 960 960"
      focusable="false"
      aria-hidden
    >
      <path d="M686-450H190q-13 0-21.5-8.5T160-480q0-13 8.5-21.5T190-510h496L459-737q-9-9-9-21t9-21q9-9 21-9t21 9l278 278q5 5 7 10t2 11q0 6-2 11t-7 10L501-181q-9 9-21 9t-21-9q-9-9-9-21t9-21l227-227Z" />
    </svg>
  );
}

export default function HeaderDesktopContentAbout(
  props: Omit<React.ComponentPropsWithoutRef<'div'>, 'children'>,
) {
  return (
    <div>
      <div>
        <HeaderDesktopContentTriple
          {...props}
          colStart={
            <HeaderDesktopContentStyles.OverviewRoot>
              <HeaderDesktopContentStyles.OverviewTitle>
                About us
              </HeaderDesktopContentStyles.OverviewTitle>
              <HeaderDesktopContentStyles.OverviewDescription>
                We&rsquo;re a holistic financial services organization driven by
                a higher purpose, helping you make the most of all you&rsquo;ve
                been given.
              </HeaderDesktopContentStyles.OverviewDescription>
            </HeaderDesktopContentStyles.OverviewRoot>
          }
          colCenter={
            <HeaderDesktopContentAboutStyles.Root>
              <li>
                <NavigationMenu.Link asChild>
                  <a href={ABOUT_US_URL} data-analytics-name="about-us/">
                    About us
                  </a>
                </NavigationMenu.Link>
              </li>
              <li>
                <NavigationMenu.Link asChild>
                  <a
                    href={PURPOSE_AND_VALUES_URL}
                    data-analytics-name="about-us/purpose-and-values"
                  >
                    Purpose & values
                  </a>
                </NavigationMenu.Link>
              </li>
              <li>
                <NavigationMenu.Link asChild>
                  <a
                    href={CLIENT_STORIES_URL}
                    data-analytics-name="about-us/client-stories"
                  >
                    Our client stories
                  </a>
                </NavigationMenu.Link>
              </li>
              <li>
                <NavigationMenu.Link asChild>
                  <a
                    href={LEADERSHIP_URL}
                    data-analytics-name="about-us/our-leadership"
                  >
                    Thrivent Leadership
                  </a>
                </NavigationMenu.Link>
              </li>
              <li>
                <NavigationMenu.Link asChild>
                  <a
                    href={FINANCIALS_URL}
                    data-analytics-name="about-us/financials"
                  >
                    Financials
                  </a>
                </NavigationMenu.Link>
              </li>
              <li>
                <NavigationMenu.Link asChild>
                  <a
                    href="https://newsroom.thrivent.com"
                    data-analytics-name="about-us/newsroom"
                  >
                    Newsroom
                  </a>
                </NavigationMenu.Link>
              </li>
              <li>
                <NavigationMenu.Link asChild>
                  <a
                    href={MEMBERSHIP_URL}
                    data-analytics-name="about-us/membership"
                  >
                    Membership
                  </a>
                </NavigationMenu.Link>
              </li>
              <li>
                <NavigationMenu.Link asChild>
                  <a
                    href="https://artcollection.thrivent.com/"
                    data-analytics-name="about-us/collection"
                  >
                    Art Collection
                  </a>
                </NavigationMenu.Link>
              </li>
              <li>
                <NavigationMenu.Link asChild>
                  <a
                    href={GENEROSITY_AND_GIVING_URL}
                    data-analytics-name="about-us/how-we-give-back"
                  >
                    Generosity & giving
                  </a>
                </NavigationMenu.Link>
              </li>
              <li>
                <NavigationMenu.Link asChild>
                  <a
                    href={OUR_HISTORY_URL}
                    data-analytics-name="about-us/history"
                  >
                    Our history
                  </a>
                </NavigationMenu.Link>
              </li>
              <li>
                <NavigationMenu.Link asChild>
                  <a
                    href={CONTACT_US_URL}
                    data-analytics-name="about-us/support"
                  >
                    Contact us
                  </a>
                </NavigationMenu.Link>
              </li>
              <li>
                <NavigationMenu.Link asChild>
                  <a
                    href={FREQUENTLY_ASKED_QUESTIONS_URL}
                    data-analytics-name="about-us/faq"
                  >
                    FAQ
                  </a>
                </NavigationMenu.Link>
              </li>
            </HeaderDesktopContentAboutStyles.Root>
          }
          colEnd={
            <HeaderDesktopContentStyles.FeatureRoot>
              <HeaderDesktopContentStyles.FeatureText>
                Featured
              </HeaderDesktopContentStyles.FeatureText>
              <HeaderDesktopContentStyles.FeatureContent>
                <HeaderDesktopContentStyles.FeatureTitle>
                  Real clients. Real stories.
                </HeaderDesktopContentStyles.FeatureTitle>
                <HeaderDesktopContentStyles.FeatureDescription>
                  See how Thrivent clients are impacting their communities and
                  following their hearts with the help of our financial advice.
                </HeaderDesktopContentStyles.FeatureDescription>
              </HeaderDesktopContentStyles.FeatureContent>
              <div>
                <HeaderDesktopContentStyles.FeatureLink
                  href={CLIENT_STORIES_URL}
                  aria-label="Explore stories from our clients"
                  data-analytics-name="about-us/client-stories"
                >
                  Explore stories <RightArrow />
                </HeaderDesktopContentStyles.FeatureLink>
              </div>
            </HeaderDesktopContentStyles.FeatureRoot>
          }
        />
      </div>
    </div>
  );
}
